import {
  GET_USER_LOGS,
  GET_USER_LOGS_SUCCESS,
  GET_USER_LOGS_FAIL,
  GET_File_UPLOAD_LOGS,
  GET_File_UPLOAD_LOGS_SUCCESS,
  GET_File_UPLOAD_LOGS_FAIL,
  UPDATE_FILE_UPLOAD_LOGS,
  UPDATE_File_UPLOAD_LOGS_SUCCESS,
  UPDATE_File_UPLOAD_LOGS_FAIL,
} from "./actionTypes";

const initialState = {
  userLogs: [],
  fileUploadLogs: [],
  updateFileUploadLogs: [],
  loading: false,
  error: {
    message: "",
    code: "",
  },
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LOGS:
      return { ...state, loading: true };

    case GET_USER_LOGS_SUCCESS:
      return { ...state, userLogs: action.payload, loading: false };

    case GET_USER_LOGS_FAIL:
      return { ...state, userLogs: [], error: action.payload, loading: false };

    case GET_File_UPLOAD_LOGS:
      return { ...state, loading: true };

    case GET_File_UPLOAD_LOGS_SUCCESS:
      return { ...state, fileUploadLogs: action.payload, loading: false };

    case GET_File_UPLOAD_LOGS_FAIL:
      return { ...state, fileUploadLogs: [], error: action.payload, loading: false };

      case UPDATE_FILE_UPLOAD_LOGS:
        return { ...state, loading: true };
  
      case UPDATE_File_UPLOAD_LOGS_SUCCESS:
        return { ...state, updateFileUploadLogs: action.payload, loading: false };
  
      case UPDATE_File_UPLOAD_LOGS_FAIL:
        return { ...state, updateFileUploadLogs: [], error: action.payload, loading: false };  

    default:
      return state;
  }
};

export default logsReducer;
