import { combineReducers } from "redux";

import AuthReducer from "./auth/reducer";
import EventsReducer from "./events/reducer";
import SecretatiesReducer from "./secretaries/reducer";
import DocTypesReducer from "./documentTypes/reducer";
import DocReducer from "./documents/reducer";
import AdminSettingsReducer from "./adminSettings/reducer";
import NotesReducer from "./showNotes/reducer";
import LogsReducer from "./logs/reducer";


const rootReducer = combineReducers({
  AuthReducer,
  EventsReducer,
  SecretatiesReducer,
  DocTypesReducer,
  DocReducer,
  AdminSettingsReducer,
  NotesReducer,
  LogsReducer
});

export default rootReducer;
