import { takeLatest, put, call } from "redux-saga/effects";

import { GET_NOTES,ADD_NOTE,EDIT_NOTE,DEL_NOTE} from "./actionTypes";

import {
  getNotesSuccess,
  getNotesFail,
  addNoteSuccess,
  addNoteFail,
  delNoteFailure,
  delNoteSuccess,
  editNoteFailure,
  editNoteSuccess
  
} from "./actions";

import { getNotes,addNote,editNote,delNote} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* onGetNotes({eventId,token}) {

  
  try {
    const response = yield call(getNotes,eventId,token);
    console.log(response);
  
    yield put(getNotesSuccess(response.data));
  } catch (error) {
    yield put(getNotesFail(error.response.data));
  }
}



function* onAddNote({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(addNote,data,token);
   // console.log(response);
    toast(response.message);
   yield put(addNoteSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(addNoteFail(error.response));
  }
}


function* onDelNote({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(delNote,data,token);
    console.log(response);
    toast(response.message);
  
    yield put(delNoteSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delNoteFailure(error.response));
  }
}




function* onEditNote({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(editNote,data,token);
   // console.log(response);
    toast(response.message);
    yield put(editNoteSuccess(response.data));
  } catch (error) {
    toast(error.response.data.message);
    yield put(editNoteFailure(error.response));
  }
}

function* NotesSaga() {

  yield takeLatest(GET_NOTES, onGetNotes);
 
  yield takeLatest(ADD_NOTE, onAddNote);
  yield takeLatest(EDIT_NOTE, onEditNote);
  yield takeLatest(DEL_NOTE, onDelNote);


}

export default NotesSaga;
