import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/logo.png";
import background from "../assets/head_back.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDocs } from "../store/adminSettings/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// header menu of after login portal
function Header() {
  const { user, logout } = useAuth();
  let dispatch = useDispatch();
  const { loadingGetDocs, docList } = useSelector(
    (state) => state.AdminSettingsReducer
  );
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [nestedDropdownOpen, setNestedDropdownOpen] = React.useState(false);

  const toggleNestedDropdown = () => setNestedDropdownOpen(!nestedDropdownOpen);

  if (!user.userData) {
    logout();
  }

  useEffect(() => {
    dispatch(getDocs());
  }, []);
  return (
    <div className="header" style={{ backgroundImage: `url(${background})` }}>
      <Navbar dark expand="md">
        <NavbarBrand href="/">
          {" "}
          <img src={logo} alt="Logo" width={200} />
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="justify-content-end" style={{ width: "100%" }} navbar>
            <NavItem>
              <NavLink href="/event-list">Dashboard</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Manage
              </DropdownToggle>

              {user.userData.role !== "secretary" ? (
                <DropdownMenu right>
                  <DropdownItem nav caret>
                    <NavLink href="/users"> All Users</NavLink>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem>
                    <NavLink href="/document-types"> Document Types</NavLink>
                  </DropdownItem>

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret onClick={() => { toggleNestedDropdown(); }} style={{ 'margin-left': '15px' }}>
                      Audit
                    </DropdownToggle>
                    {nestedDropdownOpen &&
                      <DropdownMenu right>
                        <DropdownItem>
                          <NavLink href="/user-logs">User Logs</NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink href="/file-upload-logs">File Upload Logs</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    }
                  </UncontrolledDropdown>

                  <DropdownItem style={nestedDropdownOpen ? {'margin-top':'100px'}:{'margin-top':'0px'}}>
                    <NavLink href="/settings"> Settings</NavLink>
                  </DropdownItem>

                </DropdownMenu>
              ) : (
                <DropdownMenu right>


                  {docList?.map(function (item, i) {
                    return (
                      <DropdownItem nav caret>
                        <NavLink
                          onClick={() =>
                            item.docType === "file" ? navigate("/documents", {
                              state: {
                                docItem: item,
                              },
                            }) : window.open(item.link, "_blank")
                          }
                        >
                          {item.docName}
                        </NavLink>
                      </DropdownItem>
                    );
                  })}

                  <DropdownItem nav caret>
                    <NavLink href="/change-password">
                      Reset Your Password
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {user.userData.email}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink onClick={logout}>Logout</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
