import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";

import { forgotPassword } from "../store/auth/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const { login } = useAuth();
  const { forgotPasswordResponse, loadingForgotPassword } = useSelector(
    (state) => state.AuthReducer
  );
  const navigate = useNavigate();

  let dispatch = useDispatch();

  // dispatch forgot password
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const forgotPasswordData = {
      email: data.get("email"),
    };
    console.log("forgot password data", forgotPasswordData);

    dispatch(forgotPassword(forgotPasswordData, navigate));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography>
          Forgot your account’s password or having trouble logging into your
          account? Enter your email address and we’ll send you a recovery link.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loadingForgotPassword ? "Loading..." : "Forgot Password"}
          </Button>
          <ToastContainer />
        </Box>
      </Box>
    </Container>
  );
};
