import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  SAVE_SETTINGS,
  SAVE_SETTINGS_FAILURE,
  SAVE_SETTINGS_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  GET_DOCS,
  GET_DOCS_SUCCESS,
  GET_DOCS_FAIL,

  DEL_DOC,
  DEL_DOC_FAILURE,
  DEL_DOC_SUCCESS,
} from "./actionTypes";

const initialState = {
 
  error: {
    message: "",
    code:"",
  },
  saveSettingsResponse: {},
  loading: false,

  settings: {},
  loadingSaveSetting: false,
  loadingUploadFile:false,
  uploadFileRes:{},
  loadingGetDocs:false,
  docList:[],
  delDocResponse: {},
  loadingDelDoc: false,

};

const AdminSettingsReducer = (state = initialState, action) => {


  switch (action.type) {
    case GET_DOCS:
      state = { ...state, loadingGetDocs: true };
      break;
    case GET_DOCS_SUCCESS:
      state = { ...state, docList: action.payload.documents, loadingGetDocs: false };
      break;
    case GET_DOCS_FAIL:

      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loadingGetDocs: false,
      };
      break;
    case UPLOAD_FILE:
      state = { ...state, loadingUploadFile: true };
      break;
    case UPLOAD_FILE_SUCCESS:
      state = { ...state, uploadFileRes: action.payload, loadingUploadFile: false };

      break;
    case UPLOAD_FILE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingUploadFile: false,
      };
      break;
      case DEL_DOC:
        state = { ...state, loadingDelDoc: true };
        break;
      case DEL_DOC_SUCCESS:
        state = { ...state, delDocResponse: action.payload, loadingDelDoc: false };
  
        break;
      case DEL_DOC_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingDelDoc: false,
        };
        break;
    case SAVE_SETTINGS:
      state = { ...state, loadingSaveSetting: true };
      break;
    case SAVE_SETTINGS_SUCCESS:
      state = { ...state, saveSettingsResponse: action.payload, loadingSaveSetting: false };

      break;
    case SAVE_SETTINGS_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSaveSetting: false,
      };
      break;
    case GET_SETTINGS:
      state = { ...state, loading: true };
      break;
    case GET_SETTINGS_SUCCESS:
      state = { ...state, settings: action.payload.settings, loading: false };
      break;
    case GET_SETTINGS_FAIL:

      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AdminSettingsReducer;
