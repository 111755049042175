import { takeLatest, put, call } from "redux-saga/effects";

import { GET_DOGS,ACTIVATE_ACCOUNT,RESET_USER_PASSWORD,DEL_USER} from "./actionTypes";

import {
  getDogsSuccess,
  getDogsFail,
  activateAccountSuccess,
  activateAccountFailure,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  delUserFailure,
  delUserSuccess
  
} from "./actions";

import { getSecretaries,activateAccount,resetUserPassword,delUser} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* onGetDogs({token}) {

  
  try {
    const response = yield call(getSecretaries,token);
    console.log(response);
  
    yield put(getDogsSuccess(response.data));
  } catch (error) {
    
    yield put(getDogsFail(error.response));
  }
}


function* onActivateAccount({ payload: data,navigate }) {
  try {
    const response = yield call(activateAccount,data);
    console.log(response.data);
    toast(response.message);
    if(response.data.statusCode===200){
      if(response.data.data.user.secretary_name==="")
      navigate(0);
      else
      navigate("/users");
    }

    yield put(activateAccountSuccess(response.data));
  } catch (error) {
 // toast(error.response.data.data);
    yield put(activateAccountFailure(error.response));
  }
}
function* onResetUserPassword({ payload: data,navigate }) {
  try {
    const response = yield call(resetUserPassword,data);
    console.log(response.data);
    toast(response.message);
    if(response.data.statusCode===200){

      navigate(0);
    }

    yield put(resetUserPasswordSuccess(response.data));
  } catch (error) {
  toast(error.response.data.data);
    yield put(resetUserPasswordFailure(error.response));
  }
}
function* onDelUser({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(delUser,data,token);
    console.log(response);
    toast(response.message);
    if(response.statusCode===200){
      navigate(0);

    }
    yield put(delUserSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delUserFailure(error.response));
  }
}


function* SecSaga() {

  yield takeLatest(GET_DOGS, onGetDogs);
  yield takeLatest(ACTIVATE_ACCOUNT, onActivateAccount);
  yield takeLatest(RESET_USER_PASSWORD, onResetUserPassword);
  yield takeLatest(DEL_USER, onDelUser);

}

export default SecSaga;
