import {
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_LIST,
  GET_EVENT_LIST_FAIL,
  GET_EVENT_LIST_SUCCESS,
  GET_SHOWS_LIST,
  GET_SHOWS_LIST_SUCCESS,
  GET_SHOWS_LIST_FAIL,
  REFRESH_EVENTS,
  REFRESH_EVENTS_SUCCESS,
  REFRESH_EVENTS_FAIL,
  UPDATE_EVENT_STATUS,
  UPDATE_EVENT_STATUS_SUCCESS,
  UPDATE_EVENT_STATUS_FAILURE,
  ADD_SHOW_DOCUMENT,
  ADD_SHOW_DOCUMENT_SUCCESS,
  ADD_SHOW_DOCUMENT_FAILURE,
  SET_SIGNED_OUT,
  SET_SIGNED_OUT_FAILURE,
  SET_SIGNED_OUT_SUCCESS
} from "./actionTypes";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const initialState = {
  error: {
    message: "",
    code: "",
  },
  addEventResponse: {},
  loadingAddEvent: false,

  fileEventList: [],
  loadingGetFileEventList: false,

  showsList: [],
  loadingGetShows: false,

  loadingRefreshEvents:false,
  refreshEventsResponse:{},

  loadingUpdateEventStatus:false,
  updateEventStatusRes:{},
  addShowDocResponse: {},
  loadingAddShowDoc: false,

  loaginSetSignedOutStatus:false,
  signedOutRes:{},


};

const EventsReducer = (state = initialState, action) => {


  switch (action.type) {
    case SET_SIGNED_OUT:
      state = { ...state, loaginSetSignedOutStatus: true };
      break;
    case SET_SIGNED_OUT_SUCCESS:
      state = { ...state, signedOutRes: action.payload, 
        loaginSetSignedOutStatus: false };

      break;
    case SET_SIGNED_OUT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loaginSetSignedOutStatus: false,
      };
      break;
    case UPDATE_EVENT_STATUS:
      state = { ...state, loadingUpdateEventStatus: true };
      break;
    case UPDATE_EVENT_STATUS_SUCCESS:
      state = { ...state, updateEventStatusRes: action.payload, loadingUpdateEventStatus: false };

      break;
    case UPDATE_EVENT_STATUS_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingUpdateEventStatus: false,
      };
      break;
    case ADD_EVENT:
      state = { ...state, loadingAddEvent: true };
      break;
    case ADD_EVENT_SUCCESS:
      state = { ...state, addEventResponse: action.payload, loadingAddEvent: false };

      break;
    case ADD_EVENT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddEvent: false,
      };
      break;
      case ADD_SHOW_DOCUMENT:
        state = { ...state, loadingAddShowDoc: true };
        break;
      case ADD_SHOW_DOCUMENT_SUCCESS:
        state = { ...state, addShowDocResponse: action.payload, loadingAddShowDoc: false };
  
        break;
      case ADD_SHOW_DOCUMENT_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingAddShowDoc: false,
        };
        break;
    case GET_EVENT_LIST:
      state = { ...state, loadingGetFileEventList: true };
      break;
    case GET_EVENT_LIST_SUCCESS:
      state = { ...state, 
        fileEventList: action.payload.eventList,        
        loadingGetFileEventList: false };
      break;
    case GET_EVENT_LIST_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingGetFileEventList: false,
      };
      break;

    case REFRESH_EVENTS:
      state = { ...state, loadingRefreshEvents: true };
      break;
    case REFRESH_EVENTS_SUCCESS:
      state = { ...state, refreshEventsResponse: action.payload, loadingRefreshEvents: false };
      break;
    case REFRESH_EVENTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingRefreshEvents: false,
      };
      break;


      case GET_SHOWS_LIST:
        state = { ...state, loadingGetShows: true };
        break;
      case GET_SHOWS_LIST_SUCCESS:
        state = { ...state, showsList: action.payload.showList, loadingGetShows: false };
        break;
      case GET_SHOWS_LIST_FAIL:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingGetShows: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EventsReducer;
