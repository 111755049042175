import {
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAIL,
  ADD_NOTE,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,
  EDIT_NOTE,
  EDIT_NOTE_SUCCESS,
  EDIT_NOTE_FAILURE,
  DEL_NOTE,
  DEL_NOTE_FAILURE,
  DEL_NOTE_SUCCESS
} from "./actionTypes";

const initialState = {
  noteList: [],
  loadingGetNotes: false,
  error: {
    message: "",
    code:"",
  },

  addNoteResponse: {},
  loadingAddNote: false,

  editNoteResponse: {},
  loadingEditNote: false,

  delNoteResponse: {},
  loadingDelNote: false,
};

const NotesReducer = (state = initialState, action) => {


  switch (action.type) {
    case ADD_NOTE:
      state = { ...state, loadingAddNote: true };
      break;
    case ADD_NOTE_SUCCESS:
      state = { ...state, addNoteResponse: action.payload, loadingAddNote: false };

      break;
    case ADD_NOTE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddNote: false,
      };
      break;
    case GET_NOTES:
      state = { ...state, loadingGetNotes: true };
      break;
    case GET_NOTES_SUCCESS:
      state = { ...state, noteList: action.payload.noteList, loadingGetNotes: false };
      break;
    case GET_NOTES_FAIL:

      state = {
        ...state,
        error: {
          message: action.payload.message
                },
                loadingGetNotes: false,
      };
      break;
      case DEL_NOTE:
        state = { ...state, loadingDelNote: true };
        break;
      case DEL_NOTE_SUCCESS:
        state = { ...state, delNoteResponse: action.payload, loadingDelNote: false };
  
        break;
      case DEL_NOTE_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingDelNote: false,
        };
        break;
  
        case EDIT_NOTE:
          state = { ...state, loadingEditNote: true };
          break;
        case EDIT_NOTE_SUCCESS:
          state = { ...state, editNoteResponse: action.payload, loadingEditNote: false };
    
          break;
        case EDIT_NOTE_FAILURE:
          state = {
            ...state,
            error: {
              message: "Error",
            },
            loadingEditNote: false,
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default NotesReducer;
