import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LocalStorageManager = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // List of routes that should not clear localStorage
    const exemptedRoutes = ["/event-list", "/event-documents", "/marked-catalog"];
    
    if (!exemptedRoutes.includes(location.pathname)) {
      localStorage.removeItem('specificRowIdentifier');
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default LocalStorageManager;
