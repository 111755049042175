import {
  GET_DOC_TYPES,
  GET_DOC_TYPES_SUCCESS,
  GET_DOC_TYPES_FAIL,
  DEL_DOC_TYPE,
  DEL_DOC_TYPE_FAILURE,
  DEL_DOC_TYPE_SUCCESS,
  EDIT_DOC_TYPE,
  EDIT_DOC_TYPE_SUCCESS,
  EDIT_DOC_TYPE_FAILURE,
  ADD_DOC_TYPE,
  ADD_DOC_TYPE_FAILURE,
  ADD_DOC_TYPE_SUCCESS
} from "./actionTypes";

export const addDocType= (data,navigate,token) => {
  return {
    type: ADD_DOC_TYPE,
    token:token,
    payload:data,
    navigate:navigate
  };
};

export const addDocTypeSuccess = (response) => {
  return {
    type: ADD_DOC_TYPE_SUCCESS,
    payload: response,
  };
};

export const addDocTypeFail = (error) => {
  return {
    type:ADD_DOC_TYPE_FAILURE,
    payload: error,
  };
};


export const getDocTypes= (token) => {
  return {
    type: GET_DOC_TYPES,
    token:token
  };
};

export const getDocTypesSuccess = (docTypeList) => {
  return {
    type: GET_DOC_TYPES_SUCCESS,
    payload: docTypeList,
  };
};

export const getDocTypesFail = (error) => {
  return {
    type:GET_DOC_TYPES_FAIL,
    payload: error,
  };
};




export const delDocType = (data,navigate,token) => {
  return {
    type: DEL_DOC_TYPE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delDocTypeSuccess = (delDocTypeResponse) => {
  return {
    type: DEL_DOC_TYPE_SUCCESS,
    payload:delDocTypeResponse,
  };
};

export const delDocTypeFailure = (error) => {
  return {
    type: DEL_DOC_TYPE_FAILURE,
    payload:error,
  };
};





export const editDocType = (data,token,navigate) => {
  return {
    type: EDIT_DOC_TYPE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const editDocTypeSuccess = (editDocTypeResponse) => {
  return {
    type: EDIT_DOC_TYPE_SUCCESS,
    payload:editDocTypeResponse,
  };
};

export const editDocTypeFailure = (error) => {
  return {
    type: EDIT_DOC_TYPE_FAILURE,
    payload:error,
  };
};

