import { Button } from "reactstrap";
import React from "react";
import {
  Col,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addEvent } from "../../store/events/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";

function AddNewEventsFile() {
  const { addEventResponse, loadingAddEvent } = useSelector(
    (state) => state.EventsReducer
  );
  const [file, setFile] = useState();
  const { user, logout } = useAuth();

  let dispatch = useDispatch();
  const navigate = useNavigate();

  function handleChange(e) {
    console.log("file", e.target.files);
    const files = Array.from(e.target.files);
    setFile(files[0]);
  }

  // dispatch add new event file
  const addNewEvent = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log("add new event clicked", data.get("eventName"));
    const formData = new FormData();

    formData.append("file", file);
    formData.append("event_name", data.get("eventName"));
    // formData.append('event_category', data.get("category"));

    console.log("formData >> addneweventfile >> ",formData);
    dispatch(addEvent(formData, navigate, user.token));
  };

  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">Add New Event File</p>

      <div className="page-body">
        <CardBody>
          <Form onSubmit={addNewEvent}>
            <FormGroup row>
              <Label for="eventName" sm={2}>
                File Name
              </Label>
              <Col sm={10}>
                <Input
                  id="eventName"
                  name="eventName"
                  placeholder="File name here"
                  type="text"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="eventFile" sm={2}>
                Select File
              </Label>
              <Col sm={10}>
                <Input
                  id="eventFile"
                  name="eventFile"
                  type="file"
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col
                sm={{
                  offset: 2,
                  size: 10,
                }}
              >
                <Button color="primary" outline type="submit">
                  {loadingAddEvent ? "Please wait..." : "Upload New File"}
                </Button>
                {loadingAddEvent ? (
                  <Alert color="primary">
                    We are uploading & processing your file. It can take few
                    minutes...
                  </Alert>
                ) : (
                  ""
                )}
                <ToastContainer />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </div>
    </div>
  );
}

export default AddNewEventsFile;
