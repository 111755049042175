
export const ADD_EVENT="ADD_EVENT";
export const ADD_EVENT_SUCCESS="ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE="ADD_EVENT_FAILURE";

export const GET_EVENT_LIST = "GET_EVENT_LIST";
export const GET_EVENT_LIST_SUCCESS = "GET_EVENT_LIST_SUCCESS";
export const GET_EVENT_LIST_FAIL = "GET_EVENT_LIST_FAIL";


export const REFRESH_EVENTS = "REFRESH_EVENTS";
export const REFRESH_EVENTS_SUCCESS = "REFRESH_EVENTS_SUCCESS";
export const REFRESH_EVENTS_FAIL = "REFRESH_EVENTS_FAIL";



export const GET_SHOWS_LIST = "GET_SHOWS_LIST";
export const GET_SHOWS_LIST_SUCCESS = "GET_SHOWS_LIST_SUCCESS";
export const GET_SHOWS_LIST_FAIL = "GET_SHOWS_LIST_FAIL";

export const UPDATE_EVENT_STATUS="UPDATE_EVENT_STATUS";
export const UPDATE_EVENT_STATUS_SUCCESS="UPDATE_EVENT_STATUS_SUCCESS";
export const UPDATE_EVENT_STATUS_FAILURE="UPDATE_EVENT_STATUS_FAILURE";

export const ADD_SHOW_DOCUMENT="ADD_SHOW_DOCUMENT";
export const ADD_SHOW_DOCUMENT_SUCCESS="ADD_SHOW_DOCUMENT_SUCCESS";
export const ADD_SHOW_DOCUMENT_FAILURE="ADD_SHOW_DOCUMENT_FAILURE";


export const SET_SIGNED_OUT="SET_SIGNED_OUT";
export const SET_SIGNED_OUT_SUCCESS="SET_SIGNED_OUT_SUCCESS";
export const SET_SIGNED_OUT_FAILURE="SET_SIGNED_OUT_FAILURE";
