import {  get, put ,putWithoutToken,delItem} from "./api_helper";
import * as url from "./url_helper";
import { post,postEvent,postLogin } from "./api_helper";

export const login = (data) => postLogin(url.LOGIN,data);

export const forgotPassword = (data) => post(url.FORGOT_PASSWORD,data);
export const resetPassword = (data) => putWithoutToken(url.RESET_PASSWORD,data);
export const changePassword = (data,token) => put(url.CHANGE_PASSWORD,data,token);

export const updateEventStatus = (data) => put(url.UPDATE_EVENT_STATUS,data);
export const setSignedOut = (data) => put(url.SET_SIGNED_OUT,data);

export const getEvents = (token) => get(url.GET_EVENTS,token);
export const addEvent = (data,token) => postEvent(url.ADD_EVENT,data,token);

  export const getEventList = (eventFileId,token) => get(
    url.GET_EVENTS+"/"+eventFileId,token);
    export const refreshEvents = (token) => get(
      url.REFRESH_EVENTS,token);
  export const getShowsList = (eventId,token) => get(
    url.GET_SHOWS+"/"+eventId,token);
    export const getMarkedCatalog = (event_no) => get(url.GET_SHOWS+"/marked-catalog/"+event_no);


export const getSecretaries = (token) => get(url.GET_SEC,token);
export const activateAccount = (data) => postLogin(url.ACTIVATE_ACCOUNT,data);
export const resetUserPassword = (data) => postLogin(url.RESET_USER_PASSWORD,data);
export const delUser = (data) => delItem(url.GET_SEC,data);
export const delDoc = (data) => delItem(url.DOC_DELETE,data);
export const getDocs = (eventId,token)=> get(url.GET_DOCS+"/"+eventId,token);
export const findSecretary = (searchText) => get(url.GET_SEC+"/find-sec/"+searchText);


export const getDocTypes = (token) => get(url.DOC_TYPES,token);
export const addDocType = (data,token) => post(url.DOC_TYPES,data,token);
export const editDocType = (data) => put(url.DOC_TYPES,data);
export const delDocType = (data) => delItem(url.DOC_TYPES,data);



export const getSettings = (token) => get(url.GET_SETTINGS,token);
export const saveSettings =  (data,token) => post(url.GET_SETTINGS,data,token);
export const uploadFile = (data,token) => postEvent(url.ADMIN_DOCUMENTS,data,token);
export const getAdminDocs = ()=> get(url.GET_ADMIN_DOCUMENTS);
export const delAdminDoc = (data) => delItem(url.ADMIN_DOCUMENTS,data);



export const getNotes = (eventId,token) => get(url.SHOW_NOTES+"/"+eventId,token);
export const addNote = (data,token) => post(url.SHOW_NOTES,data,token);
export const editNote = (data) => put(url.SHOW_NOTES,data);
export const delNote= (data) => delItem(url.SHOW_NOTES,data);

export const getUserLogs = () => get(url.GET_USER_LOGS);
export const getFileUploadLogs = () => get(url.GET_FILE_UPLOAD_LOGS);
export const updateFileUploadLogs = (data,token) => put(url.UPDATE_FILE_UPLOAD_LOGS,data,token);
