export const GET_DOC_TYPES = "GET_DOC_TYPES";
export const GET_DOC_TYPES_SUCCESS = "GET_DOC_TYPES_SUCCESS";
export const GET_DOC_TYPES_FAIL = "GET_DOC_TYPES_FAIL";

export const DEL_DOC_TYPE="DEL_DOC_TYPE";
export const DEL_DOC_TYPE_SUCCESS="DEL_DOC_TYPE_SUCCESS";
export const DEL_DOC_TYPE_FAILURE="DEL_DOC_TYPE_FAILURE";



export const EDIT_DOC_TYPE="EDIT_DOC_TYPE";
export const EDIT_DOC_TYPE_SUCCESS="EDIT_DOC_TYPE_SUCCESS";
export const EDIT_DOC_TYPE_FAILURE="EDIT_DOC_TYPE_FAILURE";



export const ADD_DOC_TYPE="ADD_DOC_TYPE";
export const ADD_DOC_TYPE_SUCCESS="ADD_DOC_TYPE_SUCCESS";
export const ADD_DOC_TYPE_FAILURE="ADD_DOC_TYPE_FAILURE";
