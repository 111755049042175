import {
  
  DEL_DOC,
  DEL_DOC_FAILURE,
  DEL_DOC_SUCCESS,
  GET_DOCS,
  GET_DOCS_SUCCESS,
  GET_DOCS_FAIL
} from "./actionTypes";


export const getDocs= (eventId,token) => {
  return {
    type: GET_DOCS,
    token:token,
    payload:eventId,
  };
};

export const getDocsSuccess = (docList) => {
  return {
    type: GET_DOCS_SUCCESS,
    payload: docList,
  };
};

export const getDocsFail = (error) => {
  return {
    type:GET_DOCS_FAIL,
    payload: error,
  };
};


export const delDoc = (data,token) => {
  return {
    type: DEL_DOC,
    payload: data,
    token:token
  };
};

export const delDocSuccess = (response) => {
  return {
    type: DEL_DOC_SUCCESS,
    payload:response,
  };
};

export const delDocFailure = (error) => {
  return {
    type: DEL_DOC_FAILURE,
    payload:error,
  };
};


