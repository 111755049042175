import { CardHeader, Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Circles } from 'react-loader-spinner';
import {
  Col,
  Form,
  Card,
  FormText,
  InputGroup,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../../styles.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Grid";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from '@mui/icons-material/Description';
import { Routes, Route, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getEventList,
  setSignedOut,
  refreshEvents,
  updateEventStatus,
} from "../../store/events/actions";
import {
  getNotes,
  addNote,
  delNote,
  editNote,
} from "../../store/showNotes/actions";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { addEvent } from "../../store/events/actions";
import { useSearchParams } from "react-router-dom";

import { getDocTypes } from "../../store/documentTypes/actions";
import ShowsList from "../ShowsList";
import { saveSettings, getSettings } from "../../store/adminSettings/actions";
import { Download } from "@mui/icons-material";

function EventList() {
  const { user, logout } = useAuth();

  // console.log("user role in event list", user.userData.role);
  const location = useLocation();
  // const eventFileId=location.state.eventFileId;
  const {
    fileEventList,
    addEventResponse,
    loadingGetFileEventList,
    loadingRefreshEvents,
    loadingAddEvent,
    updateEventStatusRes,
    loadingUpdateEventStatus,
    refreshEventsResponse,
    addShowDocResponse
  } = useSelector((state) => state.EventsReducer);
  const {
    loadingGetNotes,
    loadingAddNote,
    addNoteResponse,
    noteList,
    delNoteResponse,
    editNoteResponse,
    loadingEditNote,
    loadingDelNote,
  } = useSelector((state) => state.NotesReducer);
  const { dogList, loading: loadingStaff } = useSelector(
    (state) => state.SecretatiesReducer
  );

  const { settings } = useSelector((state) => state.AdminSettingsReducer);

  const [modal, setModal] = useState(false);
  const [user_id, setId] = useState();
  const [file, setFile] = useState([]);
  const [eventId, setEventId] = useState();
  const [expandedShowName, setExpandedShowName] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const secId = searchParams.get("id");
  const { docTypeList, loading } = useSelector(
    (state) => state.DocTypesReducer
  );
  const [selectedEventId, setSelectedEventId] = useState("");
  const [filteredEventList, setFilteredEventList] = useState([]);

  const [province, setProvince] = useState("All Province");
  const [filterEventStatus, setFilterEventStatus] = useState(
    location.state
      ? location.state?.master_event?.event_status
      : user.userData.role !== "secretary"
        ? "Complete"
        : "Pending"
  );
  const [eventType, setEventType] = useState("All");
  const [event_status, setEventStatus] = useState(false);
  const [searchData, setSearchData] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [eventDocUploadModal, setEventDocUploadModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  var uniqueProvinces = [];
  const [selectedEvent, setEvent] = useState({});
  const [uploadMessage, setUploadMessage] = useState();
  const [pendingEventDocuments, setPendingEventDocuments] = useState([]);
  const [pendingEventMandatoryDocuments, setPendingEventMandatoryDocuments] =
    useState([]);
  const [defaultStatus, setDefaultStatus] = useState([]);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleEventDoc = (row) => {
    setEventDocUploadModal(!eventDocUploadModal);
    setEvent(row);
    if (row.documents && row.documents.length > 0) {
      var pendingDocuments = docTypeEventList.filter(function (o1) {
        return !row.documents.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      });
      var pendingMandatoryDocuments = mandatoryEventDocuments.filter(function (
        o1
      ) {
        return !row.documents.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      });
      setPendingEventDocuments(pendingDocuments);
      setPendingEventMandatoryDocuments(pendingMandatoryDocuments);
      // console.log(
      //   "pending mandatory master event doc",
      //   pendingMandatoryDocuments
      // );
    } else {
      setPendingEventDocuments(docTypeEventList);
      setPendingEventMandatoryDocuments(mandatoryEventDocuments);
    }
    // console.log("pending event docs", pendingEventDocuments);
  };

  const toggleNotes = (row) => {
    setNotesModal(!notesModal);
    setEvent(row);
  };

  var docTypeEventList = docTypeList.filter((obj) => {
    return obj.level === "Show";
  });
  var mandatoryEventDocuments = docTypeEventList.filter((obj) => {
    return obj.mandatory === true;
  });

  // get the list of uniqueProvinces
  if (fileEventList) {

    uniqueProvinces = [
      ...new Set(fileEventList.map((item) => item.province_name)),
    ];

    const uniqueEventTypes = [
      ...new Set(fileEventList.map((item) => item.type_of_show)),
    ];
    // console.log("uniqueProvinces", uniqueProvinces);
  }

  // const [currentRow, setCurrentRow] = useState(location.state?.master_event);
  const [currentRow, setCurrentRow] = useState(null);

  useEffect(() => {
    if (filteredEventList && filteredEventList.length > 0) {
      let initialRow = null;
      const specificRowIdentifier = localStorage.getItem('specificRowIdentifier');
      if(specificRowIdentifier !== '' && specificRowIdentifier !== null){
        initialRow = filteredEventList.find(row => row.event_id === specificRowIdentifier);
      }
      setCurrentRow(initialRow);
    }
  }, [filteredEventList]);

  // navigate to master event documents
  const viewEventDocs = (index, eventId, event) => {
    navigate("/event-documents", {
      state: {
        index: index,
        eventId: eventId,
        event: event,
        master_event_name: event.show_name,
        master_event: event,
      },
    });
  };

  // navigate to list of users
  const manageUsers = () => {
    navigate("/users");
  };

  // navigate to manage document types
  const manageDocTypes = () => {
    navigate("/document-types");
  };

  useEffect(() => {
    setEventDocUploadModal(false);

    dispatch(getDocTypes(user.token));
    dispatch(getSettings(user.token));
    if (secId === null && user.userData.role !== "secretary") {
      dispatch(getEventList("", user.token));
    } else if (user.userData.role === "secretary") {
      dispatch(
        getEventList("?secretary_id=" + user.userData.user_id, user.token)
      );
    } else {
      dispatch(getEventList("?secretary_id=" + secId, user.token));
    }
  }, [
    refreshEventsResponse,
    addEventResponse,
    loadingUpdateEventStatus === false,
    addShowDocResponse
  ]);

  useEffect(() => {
    if (searchData && searchData.length > 0) {
      setFilteredEventList(
        fileEventList.filter((obj) => {
          return obj.show_name.toLowerCase().includes(searchData.toLowerCase());
        })
      );
    } else {
      setFilteredEventList(fileEventList);
    }
  }, [searchData]);

  // delete the note added on any master event
  const deleteNote = (id) => {
    const delData = {
      note_id: id,
    };
    // console.log(delData);
    dispatch(delNote(delData, navigate, user.token));
  };

  // refresh the master events, it will automatically get the new events from canuck dogs api
  const refreshEvent = (async) => {
    dispatch(refreshEvents(user.token));
  };

  // upload new documents to master event
  const handleSubmitEventDoc = async (event) => {
    event.preventDefault();
    for (const docType of docTypeEventList) {
      // Check if the document type is 'Electronic Results File'
      if (docType.document_type === "Electronic Results File") {
        const file = docType.file;
  
        // Validate if the file is a .dat file
        if (file && !file.name.toLowerCase().endsWith('.dat')) {
          // If validation fails, set the error message and return
          toast("Incorrect file type for Electronic Results. Please ensure you are uploading a file with a .dat file extension.");
          return;
        }
      }
    }

    const newDocumentToUpload = docTypeEventList.filter((obj) => {
      return obj["file"] !== undefined;
    });
    // console.log("final", newDocumentToUpload);

    var docStatus;
    var newPendingMandatoryDocuments = pendingEventMandatoryDocuments.filter(
      function (o1) {
        return !newDocumentToUpload.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      }
    );
    // console.log(
    //   "total event mandatory pending now",
    //   newPendingMandatoryDocuments.length
    // );
    if (newPendingMandatoryDocuments.length > 0) {
      docStatus = "Updated";
    } else {
      docStatus = "Complete";
    }
    // docStatus = "Updated";
    for (var i = 0; i < newDocumentToUpload.length; i++) {
      setUploadMessage(
        "Uploading " +
        (i + 1) +
        " of " +
        newDocumentToUpload.length +
        " Documents."
      );
      const formData = new FormData();

      formData.append("file", newDocumentToUpload[i].file);
      formData.append("type", newDocumentToUpload[i].document_type);
      formData.append("doc_type_id", newDocumentToUpload[i].doc_type_id);

      formData.append("showName", "");
      formData.append("show_id", "");
      formData.append("show_status", "");

      formData.append("event_id", selectedEvent.event_id);
      formData.append("event_status", docStatus);
      formData.append("user_id", user.userData.user_id);

      // formData.append('event_category', data.get("category"));

      console.log("formData >> eventList >> before >> ",formData);
      await dispatch(addEvent(formData, navigate, user.token));
    }
  };

  useEffect(() => {
    dispatch(getNotes("?showId=" + selectedEvent.canuck_event_id, user.token));
  }, [
    notesModal,
    loadingAddNote === false,
    loadingEditNote === false,
    loadingDelNote === false,
  ]);

  // dispatch add new note to master event
  const handleAddNote = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let noteBy = "";
    if (user.userData.role === "secretary") {
      noteBy = "secretary[" + user.userData.secretary_name + "]";
    } else if (user.userData.role === "staff") {
      noteBy = "staff[" + user.userData.staff_name + "]";
    } else {
      noteBy = "Admin";
    }
    const noteData = {
      note: data.get("newNote"),
      noteBy: noteBy,
      noteById: user.userData.user_id,
      showId: selectedEvent.canuck_event_id,
    };
    // console.log("addnote data", noteData);

    dispatch(addNote(noteData, navigate, user.token));
  };

  useEffect(() => {

    var isIncludeProvince = false,
      isIncludeEventType = false;
    let filteredEventList = Array;
    if (province !== "All Province") isIncludeProvince = true;

    if (eventType !== "All") isIncludeEventType = true;

    if (isIncludeEventType && isIncludeProvince)
      filteredEventList = fileEventList.filter((obj) => {
        return (
          obj.province_name === province &&
          obj.event_status === filterEventStatus &&
          obj.type_of_show === eventType
        );
      });
    else if (isIncludeProvince)
      filteredEventList = fileEventList.filter((obj) => {
        return (
          obj.province_name === province &&
          obj.event_status === filterEventStatus
        );
      });
    else if (isIncludeEventType)
      filteredEventList = fileEventList.filter((obj) => {
        return (
          obj.event_status === filterEventStatus &&
          obj.type_of_show === eventType
        );
      });
    else
      filteredEventList = fileEventList.filter((obj) => {
        return obj.event_status === filterEventStatus;
      });

    if (user.userData.role === "secretary") {
      switch (filterEventStatus) {
        case "Complete":
          filteredEventList.sort(
            (a, b) =>
              new Date(b.start_date).getTime() -
              new Date(a.start_date).getTime()
          );
          break;
        case "Pending":
          filteredEventList.sort(
            (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );

          break;
        default:
          break;
      }
    } else {
      switch (filterEventStatus) {
        case "Complete":
          filteredEventList.sort(
            (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );
          break;
        case "Pending":
          filteredEventList.sort(
            (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );
          break;

        case "Official":
          filteredEventList.sort(
            (a, b) =>
              new Date(b.start_date).getTime() -
              new Date(a.start_date).getTime()
          );
          break;

        case "Results Entry":
          filteredEventList.sort(
            (a, b) =>
              new Date(a.start_date).getTime() -
              new Date(b.start_date).getTime()
          );

          break;
        default:
          break;
      }
    }

    setFilteredEventList(filteredEventList);
  }, [province, filterEventStatus, eventType, fileEventList]);

  // filters to show list of master events
  const handleSubmitFilter = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get("eventType"));

    //let eventName=data.get("eventName");
    let province = data.get("province");
    let status = data.get("status");
    let filteredEventList = Array;
    if (province !== "All Province" && status !== "All Status") {
      filteredEventList = fileEventList.filter((obj) => {
        return obj.province_name === province && obj.event_status === status;
      });
    } else if (province !== "All Province") {
      filteredEventList = fileEventList.filter((obj) => {
        return obj.province_name === province;
      });
    } else if (status !== "All Status") {
      filteredEventList = fileEventList.filter((obj) => {
        return obj.event_status === status;
      });
    } else {
      filteredEventList = fileEventList;
    }
    // console.log(filteredEventList);
    setFilteredEventList(filteredEventList);
  };

  // handle the change of input file
  function handleChange(e) {
    // console.log("file", e.target.id);
    const files = Array.from(e.target.files);
    console.log("files >> ",files);
    const index = docTypeEventList.findIndex(
      (x) => x.doc_type_id === e.target.id
    );
    docTypeEventList[index].file = files[0];
    //setFile({doc_type_id:e.target.id,doc_type_file:files[0]});
  }

  // dispatch update the status of master event
  const handleUpdateEventStatus = async (row, eventStatus) => {
    if (user.userData.role !== "secretary" || row.event_status === "Pending") {
      const data = {
        canuck_event_id: row.canuck_event_id,
        event_status: eventStatus,
      };
      // console.log("edit doc type data", data);

      dispatch(updateEventStatus(data, user.token, navigate));
    } else {
      toast("You don't have permissions to change the status of this event.");
    }
  };

  // dispatch set signed out any event to staff
  const handleSetSignedOut = async (row, staff_id) => {
    if (user.userData.role !== "secretary") {
      const data = {
        canuck_event_id: row.canuck_event_id,
        staff_id: staff_id,
      };
      // console.log("singed Out data", data);

      dispatch(setSignedOut(data, user.token));
    } else {
      toast("You don't have permissions to change the Signed Outs");
    }
  };

  // console.log("in event", location.state ? location.state.master_event : "");

  // columns for the list of master events
  const columns = useMemo(
    () => [
      {
        name: "Show Name/Club",
        sortable: true,
        wrap: true,
        grow: 2,
        class: "club_name",
        selector: (row) => row.show_name,
        cell: (row) => (
          <Row>
            <p className="event_name">{row.show_name}</p>
          </Row>
        ),
      },
      {
        name: "Event Type",
        selector: (row) => row.type_of_show,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Province",
        selector: (row) => row.province_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Start Date",
        selector: (row) => row.start_date.split(" ")[0],
        sortable: true,
        wrap: true,
      },
      {
        name: "End Date",
        selector: (row) => row.end_date.split(" ")[0],
        sortable: true,
        wrap: true,
      },
      {
        name: "Document Status",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            <p className={"document_status_" + row.doc_status.toLowerCase()}>
              {row.doc_status}
            </p>
          </Row>
        ),
      },

      {
        name: "Event Status",
        ignoreRowClick: true,
        grow: 3,
        center: true,
        cell: (row) =>
          user.userData.role === "secretary" ? (
            <Input
              id="updateEventStatus"
              type="select"
              value={row.event_status.trim()}
              name="updateEventStatus"
              onChange={(e) => handleUpdateEventStatus(row, e.target.value)}
            >
              <option>Pending</option>
              <option>Complete</option>
            </Input>
          ) : (
            <Input
              id="updateEventStatus"
              type="select"
              value={row.event_status.trim()}
              name="updateEventStatus"
              onChange={(e) => handleUpdateEventStatus(row, e.target.value)}
            >
              <option>Pending</option>
              <option>Complete</option>
              <option>Results Entry</option>
              <option>Official</option>
            </Input>
          ),
      },
      {
        name: "Documents",
        ignoreRowClick: true,
        center: true,
        width: "220px",
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="View"
                className="manage-event"
                onClick={() => viewEventDocs(row.index, row.event_id, row)}
              >
                <VisibilityIcon />
                View
              </Button>
              {row.event_status === "Pending" ? (
                <Button
                  title="Upload"
                  className="manage-event"
                  onClick={() => toggleEventDoc(row)}
                >
                  <FileUploadIcon />
                  Upload
                </Button>
              ) : (
                ""
              )}
              {user.userData.role !== "secretary" ? (

                <Button
                  title="DNLD"
                  className="manage-event"
                  href={
                    process.env.REACT_APP_APP_URL +
                    "api/v1/document/all/" +
                    row.event_id
                  }
                >
                  <Download />
                  DNLD
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ),
      },
      {
        name: "Signed Out",
        ignoreRowClick: true,
        grow: 2,
        center: true,
        cell: (row) => (
          <Row>
            <Col>
              <Input
                id="signedOut"
                type="select"
                name="signedOut"
                defaultValue={row?.signedOuts[0]?.staff_id}
                onChange={(e) => handleSetSignedOut(row, e.target.value)}
                placeholder="Signed Out"
                disabled={user.userData.role === 'staff' || user.userData.role === 'secretary'}
              >
                <option selected value={"select"}>
                  {"Select"}
                </option>
                {row?.staffList?.map(function (item, i) {
                  return (
                    <option value={item._id}>
                      {item.staff_name.split(" ")[0]}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
        ),
      },
      {
        name: "Notes",
        ignoreRowClick: true,
        grow: 2,
        center: true,
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="Notes"
                className="notes_button"
                size="sm"
                onClick={() => toggleNotes(row)}
              >
                <DescriptionIcon />
                Event Notes
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );
  return (
    <div>
      <div className="page-heading-section">
        <div class="left-btn">
          {user.userData.role === "admin" ? (
            <p className="event-page-heading">All Events</p>
          ) : (
            <p className="event-page-heading">
              My Events[
              {user.userData.secretary_name
                ? user.userData.secretary_name
                : user.userData.staff_name}
              ]
            </p>
          )}
        </div>
        <div class="right-btn">
          {!loadingGetFileEventList ? (
            user.userData.role === "secretary" ? (
              ""
            ) : (
              // <Button variant="contained" onClick={() => toggle()}>Upload New Result Document</Button>
              <div>
                {/* <Button className='manage-user-btn' variant="contained" onClick={() => toggle()}>Upload New Result Document</Button> */}
                <Button
                  className="manage-user-btn"
                  variant="contained"
                  onClick={() => manageUsers()}
                >
                  Manage Users
                </Button>
                <Button
                  className="manage-types-btn"
                  variant="contained"
                  onClick={() => manageDocTypes()}
                >
                  Document Types
                </Button>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <ToastContainer />

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <Row>
              <Col sm={8}>
                <Form onSubmit={handleSubmitFilter}>
                  <InputGroup>
                    {/* <Input
                    id='eventName'
                    name='eventName'
                    type='text'
                    placeholder='Search by event name' /> */}

                    <Input
                      id="province"
                      type="select"
                      name="province"
                      onChange={(e) => setProvince(e.target.value)}
                      placeholder="Province"
                    >
                      <option>All Province</option>
                      {uniqueProvinces.map(function (item, i) {
                        return <option>{item}</option>;
                      })}
                    </Input>
                    {user.userData.role === "secretary" ? (
                      <Input
                        id="filterEventStatus"
                        type="select"
                        name="filterEventStatus"
                        defaultValue={filterEventStatus}
                        onChange={(e) => setFilterEventStatus(e.target.value)}
                        placeholder="Province"
                      >
                        {/* <option>All Status</option> */}
                        <option>Pending</option>
                        <option>Complete</option>
                      </Input>
                    ) : (
                      <Input
                        id="filterEventStatus"
                        type="select"
                        name="filterEventStatus"
                        defaultValue={filterEventStatus}
                        onChange={(e) => setFilterEventStatus(e.target.value)}
                        placeholder="Province"
                      >
                        {/* <option>All Status</option> */}

                        <option>Complete</option>
                        <option>Results Entry</option>
                        <option>Official</option>
                        <option>Pending</option>
                      </Input>
                    )}

                    <Input
                      id="eventType"
                      name="eventType"
                      onChange={(e) => setEventType(e.target.value)}
                      type="select"
                      placeholder="Event Type"
                    >
                      <option>All</option>
                      <option>Conformation</option>
                      <option>Specialty - Conformation</option>

                      {/* {uniqueEventTypes.map(function (item, i) {
                        return <option>{item}</option>
                      })} */}
                    </Input>

                    <Input
                      id="showName"
                      name="showName"
                      onChange={(e) => setSearchData(e.target.value)}
                      type="text"
                      placeholder="Search by show name"
                    ></Input>
                    {/* <Button variant="contained" color="primary" type="submit" className='search-button'> Apply Filter</Button> */}
                  </InputGroup>
                </Form>
              </Col>
              <Col sm={2} />
              <Col sm={2}>
                {!loadingGetFileEventList ? (
                  <Button variant="outlined" onClick={() => refreshEvent()}>
                    {loadingRefreshEvents ? "loading..." : "Refresh Events"}
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="hotel-locations-card-body">
            <DataTable
              columns={columns}
              theme=""
              data={filteredEventList}
              highlightOnHover
              fixedHeader
              expandableRows
              // defaultSortFieldId={4}
              // defaultSortAsc={sortOrder}

              expandableRowExpanded={(row) => row === currentRow}
              expandOnRowClicked
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}
              // expandableRowExpanded={(row) =>
              //   row.show_name ===
              //   (location.state ? location.state.master_event.show_name : "")
              // }
              expandableRowsComponent={ShowsList}
              progressPending={loadingGetFileEventList}
              pagination
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              sortIcon={<FilterListIcon />}
            />
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={eventDocUploadModal} toggle={toggleEventDoc}>
        <ModalHeader toggle={toggleEventDoc}>
          Upload Show Level Documents
        </ModalHeader>
        <ModalBody>


          <Form onSubmit={handleSubmitEventDoc}
          className={loadingAddEvent?'doc_upload_form':''}>
            <FormGroup col>
              <Label for="eventName" sm={12}>
                Show Name : <b>{selectedEvent.show_name}</b>
              </Label>
            </FormGroup>
            {/* <FormGroup col>
              <Label
                for="eventNo"
                sm={12}
              >
              CKC Event Number :  <b>{selectedEvent.canuck_event_id}</b>
              </Label>

            </FormGroup> */}

            {docTypeEventList
              ? docTypeEventList.map(function (item, i) {
                return (
                  <FormGroup col>
                    <Label for="docType" sm={12}>
                      {item.document_type}
                      {item.mandatory ? " *" : ""}
                    </Label>
                    <Col sm={12}>
                      <Input
                        id={item.doc_type_id}
                        name="documentFile"
                        type="file"
                        onChange={handleChange}
                      />
                    </Col>
                  </FormGroup>
                );
              })
              : ""}
            <Row>
              <Col>
                <Button fullWidth variant="contained" color="primary" type="submit">
                  {loadingAddEvent ? "Uploading..." : "Submit"}
                </Button>
              </Col>
              <Col>
                <Button fullWidth variant="outlined" color="primary" onClick={()=>setEventDocUploadModal(false)} >
                  Exit
                </Button>
              </Col>
            </Row>
           

          </Form>
          {loadingAddEvent ?
        
     <Circles
     height="80"
     width="80"
     color="#C72222"
     ariaLabel="loading"
     wrapperClass="loader_spinner"
   />
 : ""}
        </ModalBody>
        <ModalFooter>{uploadMessage}</ModalFooter>
      </Modal>

      <Modal isOpen={notesModal} toggle={toggleNotes}>
        <ModalHeader toggle={toggleNotes}>
          Notes[{selectedEvent.show_name}]
        </ModalHeader>
        <ModalBody>
          {noteList
            ? noteList.map(function (item, i) {
              return (
                <div className="notes_div">
                  <Label for="note" sm={12}>
                    {item.note}
                  </Label>
                  <Label for="noteBy" className="noteBy" sm={12}>
                    Added By: {item.noteBy}
                  </Label>
                  <Row sm={12}>
                    <Button
                      className="noteBy"
                      sm={6}
                      onClick={() => deleteNote(item.note_id)}
                    >
                      Delete
                    </Button>
                  </Row>
                </div>
              );
            })
            : "No Notes Found!"}
          <Form onSubmit={handleAddNote}>
            <FormGroup>
              <Row sm={12}>
                <Col sm={10}>
                  <Input
                    id="newNote"
                    name="newNote"
                    type="text"
                    required
                    maxLength={2000}
                    placeholder="Add New Note"
                  />
                </Col>
                <Col sm={2}>
                  <Button variant="contained" color="primary" type="submit">
                    {loadingAddNote ? "Adding..." : "Add"}
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default EventList;
