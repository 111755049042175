import {
  GET_DOGS,
  GET_DOGS_SUCCESS,
  GET_DOGS_FAIL,
  ACTIVATE_ACCOUNT,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  DEL_USER,
  DEL_USER_SUCCESS,
  DEL_USER_FAILURE
} from "./actionTypes";

export const getDogs= (token) => {
  return {
    type: GET_DOGS,
    token:token
  };
};

export const getDogsSuccess = (dogList) => {
  return {
    type: GET_DOGS_SUCCESS,
    payload: dogList,
  };
};

export const getDogsFail = (error) => {
  return {
    type:GET_DOGS_FAIL,
    payload: error,
  };
};


export const activateAccount = (data,navigate) => {
  return {
    type: ACTIVATE_ACCOUNT,
    payload: data,
    navigate:navigate,
  };
};

export const activateAccountSuccess = (activateAccountResponse) => {

  console.log("activateAccount successsss");

  return {
    type: ACTIVATE_ACCOUNT_SUCCESS,
    payload:activateAccountResponse,
  };
};

export const activateAccountFailure = (error) => {

  return {
    type: ACTIVATE_ACCOUNT_FAILURE,
    payload:error,
  };
};


export const resetUserPassword = (data,navigate) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const resetUserPasswordSuccess = (resetUserPasswordResponse) => {

  console.log("activateAccount successsss");

  return {
    type: RESET_USER_PASSWORD_SUCCESS,
    payload:resetUserPasswordResponse,
  };
};

export const resetUserPasswordFailure = (error) => {

  return {
    type: RESET_USER_PASSWORD_FAILURE,
    payload:error,
  };
};

export const delUser = (data,navigate,token) => {
  return {
    type: DEL_USER,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delUserSuccess = (delUserResponse) => {
  return {
    type:DEL_USER_SUCCESS,
    payload:delUserResponse,
  };
};

export const delUserFailure = (error) => {
  return {
    type: DEL_USER_FAILURE,
    payload:error,
  };
};
