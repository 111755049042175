import { takeLatest, put, call } from "redux-saga/effects";
import {
  GET_File_UPLOAD_LOGS,
  GET_USER_LOGS,
  UPDATE_FILE_UPLOAD_LOGS,
} from "./actionTypes";

import {
  getUserLogsSuccess,
  getUserLogsFail,
  getFileUploadLogsSuccess,
  getFileUploadLogsFail,
  updateFileUploadLogsSuccess,
  updateFileUploadLogsFail,
} from "./actions";

import { getFileUploadLogs, getUserLogs, updateFileUploadLogs } from "../../helpers/backend_helper";

// Worker Saga: Fetch logs
function* fetchUserLogs() {
  try {
    const response = yield call(getUserLogs);
    yield put(getUserLogsSuccess(response.data));
  } catch (error) {
    yield put(getUserLogsFail(error.response));
  }
}

function* fetchFileUploadLogs() {
  try {
    const response = yield call(getFileUploadLogs);
    yield put(getFileUploadLogsSuccess(response.data));
  } catch (error) {
    yield put(getFileUploadLogsFail(error.response));
  }
}

function* changeFileUploadLogs({ payload }) {
  const { formDataObj, token } = payload;
  try {
    const response = yield call(updateFileUploadLogs, formDataObj, token);
    yield put(updateFileUploadLogsSuccess(response.data));
  } catch (error) {
    yield put(updateFileUploadLogsFail(error.response));
  }
}

function* logsSaga() {
  yield takeLatest(GET_USER_LOGS, fetchUserLogs);
  yield takeLatest(GET_File_UPLOAD_LOGS, fetchFileUploadLogs);
  yield takeLatest(UPDATE_FILE_UPLOAD_LOGS, changeFileUploadLogs);
}

export default logsSaga;
