import {
  
  ADD_EVENT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_LIST,
  GET_EVENT_LIST_FAIL,
  GET_EVENT_LIST_SUCCESS,
  GET_SHOWS_LIST,
  GET_SHOWS_LIST_SUCCESS,
  GET_SHOWS_LIST_FAIL,
  REFRESH_EVENTS,
  REFRESH_EVENTS_SUCCESS,
  REFRESH_EVENTS_FAIL,
  UPDATE_EVENT_STATUS,
  UPDATE_EVENT_STATUS_SUCCESS,
  UPDATE_EVENT_STATUS_FAILURE,
  ADD_SHOW_DOCUMENT,
  ADD_SHOW_DOCUMENT_SUCCESS,
  ADD_SHOW_DOCUMENT_FAILURE,
  SET_SIGNED_OUT,
  SET_SIGNED_OUT_SUCCESS,
  SET_SIGNED_OUT_FAILURE
} from "./actionTypes";


export const addShowDocument = (data,navigate,token) => {
  return {
    type: ADD_SHOW_DOCUMENT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addShowDocumentSuccess = (addEventResponse) => {
  return {
    type: ADD_SHOW_DOCUMENT_SUCCESS,
    payload:addEventResponse,
  };
};

export const addShowDocumentFailure = (error) => {
  return {
    type: ADD_SHOW_DOCUMENT_FAILURE,
    payload:error,
  };
};

export const addEvent = (data,navigate,token) => {
  return {
    type: ADD_EVENT,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const addEventSuccess = (addEventResponse) => {
  return {
    type: ADD_EVENT_SUCCESS,
    payload:addEventResponse,
  };
};

export const addEventFailure = (error) => {
  return {
    type: ADD_EVENT_FAILURE,
    payload:error,
  };
};


export const getEventList= (eventFileId,token) => {
  return {
    type: GET_EVENT_LIST,
    payload:eventFileId,
token:token
  };
};

export const getEventListSuccess = (eventList) => {
  return {
    type: GET_EVENT_LIST_SUCCESS,
    payload: eventList,
  };
};

export const getEventListFail = (error) => {
  return {
    type: GET_EVENT_LIST_FAIL,
    payload: error,
  };
};


export const refreshEvents= (token) => {
  return {
    type: REFRESH_EVENTS,
token:token
  };
};

export const refreshEventsSuccess = (eventList) => {
  return {
    type: REFRESH_EVENTS_SUCCESS,
    payload: eventList,
  };
};

export const refreshEventsFail = (error) => {
  return {
    type: REFRESH_EVENTS_FAIL,
    payload: error,
  };
};



export const getShowsList= (eventId,token) => {
  return {
    type: GET_SHOWS_LIST,
    payload:eventId,
token:token
  };
};

export const getShowsListSuccess = (showsList) => {
  return {
    type: GET_SHOWS_LIST_SUCCESS,
    payload: showsList,
  };
};

export const getShowsListFail = (error) => {
  return {
    type: GET_SHOWS_LIST_FAIL,
    payload: error,
  };
};



export const updateEventStatus = (data,token,navigate) => {
  return {
    type: UPDATE_EVENT_STATUS,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const updateEventStatusSuccess = (res) => {
  return {
    type: UPDATE_EVENT_STATUS_SUCCESS,
    payload:res,
  };
};

export const updateEventStatusFailure = (error) => {
  return {
    type: UPDATE_EVENT_STATUS_FAILURE,
    payload:error,
  };
};




export const setSignedOut = (data,token) => {
  return {
    type: SET_SIGNED_OUT,
    payload: data,
    token:token
  };
};

export const setSignedOutSuccess = (res) => {
  return {
    type: SET_SIGNED_OUT_SUCCESS,
    payload:res,
  };
};

export const setSignedOutFailure = (error) => {
  return {
    type: SET_SIGNED_OUT_FAILURE,
    payload:error,
  };
};
