import { Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Col,
  Form,
  Card,
  FormText,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../styles.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../hooks/useAuth";
import { useState, useCallback } from "react";
import { addShowDocument } from "../store/events/actions";
import Button from "@mui/material/Button";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useNavigate } from "react-router-dom";

import { getShowsList } from "../store/events/actions";

function ShowsList({ data }) {
  const { user, logout } = useAuth();
  // console.log("user role in event list", user.userData.role);
  const { showsList, loadingGetShows, addShowDocResponse, loadingAddShowDoc} = useSelector(
    (state) => state.EventsReducer
  );
  const { docTypeList, loading } = useSelector(
    (state) => state.DocTypesReducer
  );

  const navigate = useNavigate();
  const [showDocUploadModal, setShowDocUploadModal] = useState(false);
  const [selectedShow, setShow] = useState({});
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [pendingMandatoryDocuments, setPendingMandatoryDocuments] = useState(
    []
  );

  const toggleShowDoc = (row) => {
    setShowDocUploadModal(!showDocUploadModal);
    setShow(row);

    if (row.documents && row.documents.length > 0) {
      var pendingDocuments = docTypeShowList.filter(function (o1) {
        return !row.documents.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      });
      var pendingMandatoryDocuments = mandatoryDocuments.filter(function (o1) {
        return !row.documents.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      });
      setPendingDocuments(pendingDocuments);
      setPendingMandatoryDocuments(pendingMandatoryDocuments);
      // console.log("pending mandatory", pendingMandatoryDocuments);
    } else {
      setPendingDocuments(docTypeShowList);
      setPendingMandatoryDocuments(mandatoryDocuments);
    }
  };
  var docTypeShowList = docTypeList.filter((obj) => {
    return obj.level === "Event";
  });
  var mandatoryDocuments = docTypeShowList.filter((obj) => {
    return obj.mandatory === true;
  });

  // dispatch add new sub event documents
  const handleSubmitShowDoc = async (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
    const newDocumentToUpload = docTypeShowList.filter((obj) => {
      return obj["file"] !== undefined;
    });
    // console.log("final", newDocumentToUpload);
    var docStatus;
    var newPendingMandatoryDocuments = pendingMandatoryDocuments.filter(
      function (o1) {
        return !newDocumentToUpload.some(function (o2) {
          return o1.doc_type_id === o2.doc_type_id; // return the ones with equal id
        });
      }
    );
    // console.log(
    //   "total mandatory pending now",
    //   newPendingMandatoryDocuments.length
    // );
    if (newPendingMandatoryDocuments.length > 0) {
      docStatus = "Updated";
    } else {
      docStatus = "Complete";
    }
    for (var i = 0; i < newDocumentToUpload.length; i++) {
      const formData = new FormData();

      formData.append("file", newDocumentToUpload[i].file);
      formData.append("type", newDocumentToUpload[i].document_type);
      formData.append("doc_type_id", newDocumentToUpload[i].doc_type_id);

      formData.append("showName", selectedShow.event_name);
      formData.append("show_id", selectedShow.show_id);
      formData.append("show_status", docStatus);

      formData.append("event_id", selectedShow.event_id);
      formData.append("event_status", "");
      formData.append("user_id", user.userData.user_id);

      // formData.append('event_category', data.get("category"));

      // console.log(formData);
      await dispatch(addShowDocument(formData, navigate, user.token));
    }
  };
  // handle the change event of input file
  function handleChange(e) {
    // console.log("file", e.target.id);
    const files = Array.from(e.target.files);
    const index = docTypeShowList.findIndex(
      (x) => x.doc_type_id === e.target.id
    );
    docTypeShowList[index].file = files[0];
    // console.log(docTypeShowList);
    //setFile({doc_type_id:e.target.id,doc_type_file:files[0]});
  }

  // navigate to sub event docs
  const viewEventDocs = (index, eventId, event) => {
    navigate("/event-documents", {
      state: {
        index: index,
        eventId: eventId,
        event: event,
        master_event_name: data.show_name,
        master_event: data,
      },
    });
  };

  // navigate to marked catalog page
  const viewMarkedCatalog = (index, eventId, event) => {
    navigate("/marked-catalog", {
      state: {
        index: index,
        eventId: eventId,
        event: event,
        club_name: data.show_name,
        master_event: data,
      },
    });
  };

  let dispatch = useDispatch();

  // dispatch get sub events list using  master event id
  useEffect(() => {
    localStorage.setItem('specificRowIdentifier', data.event_id);
    console.log('showList');
    setShowDocUploadModal(false);
    dispatch(getShowsList("?event_id=" + data.event_id, user.token));
  }, [addShowDocResponse]);

  const columns = useMemo(
    () => [

      {
        name: "CKC Number",
        selector: (row) => row.ckc_number,
        sortable: true,
        wrap: true,
      },
      {
        name: "Event Name",
        sortable: true,
        selector: (row) => row.event_name,

        wrap: true,
        cell: (row) => (
          <Row>
            <p className="event_name">{row.event_name}</p>
          </Row>
        ),
      },
      {
        name: "Event Type",
        selector: (row) => row.type_of_results,
        sortable: true,
        wrap: true,
      },

      {
        name: "Date",
        selector: (row) => row.event_date.split(" ")[0],
        sortable: true,
        wrap: true,
      },

      {
        name: "Document Status",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            <p className={"document_status_" + row.doc_status.toLowerCase()}>
              {row.doc_status}
            </p>
          </Row>
        ),
      },
      {
        name: "Documents",
        ignoreRowClick: true,
        width: "220px",
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="View"
                className="manage-event"
                onClick={() => viewEventDocs(row.index, row.event_id, row)}
              >
                <VisibilityIcon />
                View
              </Button>
              {data.event_status === "Pending" ? (
                <Button
                  title="Upload"
                  className="manage-event"
                  onClick={() => toggleShowDoc(row)}
                >
                  <FileUploadIcon />
                  Upload
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ),
      },
      {
        name: "Marked Catalog",
        ignoreRowClick: true,
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="marked"
                onClick={() => viewMarkedCatalog(row.index, row.event_id, row)}
              >
                <CheckBoxIcon />
                Marked
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );

  return (
    <div style={{ background: "#f00" }}>
      <ToastContainer />

      <div>
        <Card className="my-2" style={{ padding: 0, background: "#f00" }}>
          <CardBody style={{ padding: 0, background: "#f00" }}>
            <DataTable
              columns={columns}
              theme=""
              data={showsList}
              highlightOnHover
              fixedHeader
              progressPending={loadingGetShows}
              sortIcon={<FilterListIcon />}
            />
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={showDocUploadModal} toggle={toggleShowDoc}>
        <ModalHeader toggle={toggleShowDoc}>
          Upload Event Level Documents
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitShowDoc}
          className={loadingAddShowDoc?'doc_upload_form':''}>
            <FormGroup col>
              <Label for="eventName" sm={12}>
                Show Name : <b>{data.show_name}</b>
              </Label>
            </FormGroup>
            <FormGroup col>
              <Label for="eventNo" sm={12}>
                CKC Event Number : <b>{selectedShow.ckc_number}</b>
              </Label>
            </FormGroup>
            <FormGroup col>
              <Label for="eventNo" sm={12}>
                Event Name : <b>{selectedShow.event_name}</b>
              </Label>
            </FormGroup>

            {docTypeShowList
              ? docTypeShowList.map(function (item, i) {
                  return (
                    <FormGroup col>
                      <Label for="docType" sm={12}>
                        {item.document_type}
                        {item.mandatory ? " *" : ""}
                      </Label>
                      <Col sm={12}>
                        <Input
                          id={item.doc_type_id}
                          name="documentFile"
                          type="file"
                          onChange={handleChange}
                        />
                      </Col>
                    </FormGroup>
                  );
                })
              : ""}
  <Row>
              <Col>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {loadingAddShowDoc ? "Uploading..." : "Submit"}
            </Button>
            </Col>
            <Col>
                <Button fullWidth variant="outlined" color="primary" onClick={()=>setShowDocUploadModal(false)} >
                  Exit
                </Button>
              </Col>
            </Row>
          </Form>

          {loadingAddShowDoc ?
        
        <Circles
        height="80"
        width="80"
        color="#C72222"
        ariaLabel="loading"
        wrapperClass="loader_spinner"
      />
    : ""}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ShowsList;
