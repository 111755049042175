import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Header from "./Header";
import classNames from "classnames";
import { Container } from "reactstrap";
import React, { useState } from "react";

export const ProtectedLayout = () => {
  const { user, logout } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="App wrapper">
      <Container fluid>
        <Header />
        {outlet}
      </Container>
    </div>
  );
};
