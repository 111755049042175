import { Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Col,
  Form,
  Card,
  ButtonGroup,
  FormText,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "../styles.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import Grid from "@mui/material/Grid";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getDocTypes,
  delDocType,
  editDocType,
  addDocType,
} from "../store/documentTypes/actions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { addEvent } from "../store/events/actions";
import { useSearchParams } from "react-router-dom";
import { CenterFocusStrong } from "@mui/icons-material";

function DocTypeList() {
  const { user, logout } = useAuth();
  console.log("user role in event list", user.userData.role);
  const location = useLocation();
  // const eventFileId=location.state.eventFileId;
  const {
    docTypeList,
    loading,
    loadingAddDocType,
    loadingDelDocType,
    editDoCTypeResponse,
    loadingEditDocType,
  } = useSelector((state) => state.DocTypesReducer);
  const [modal, setModal] = useState(false);
  const [user_id, setId] = useState();
  const [file, setFile] = useState();
  const [eventId, setEventId] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const secId = searchParams.get("id");
  const [showsList, setShowsList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [del_id, setDelId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [edit_doc_type, setEditDocType] = useState({});
  const [rSelected, setRSelected] = useState(null);
  const toggle = (user_id) => {
    setModal(!modal);
    setId(user_id);
  };
  const deleteToggle = (del_id) => {
    setDelModal(!delModal);
    setDelId(del_id);
  };
  const editToggle = (row) => {
    setEditModal(!editModal);
    setEditDocType(row);
  };
  const navigate = useNavigate();

  if (user.userData.role === "secretary") {
    navigate("/event-list");
  }
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDocTypes(user.token));
  }, []);

  // columns for the list of document type
  const columns = useMemo(
    () => [
      {
        name: "Document Type",
        selector: (row) => row.document_type,
        sortable: true,
        wrap: true,
      },
      {
        name: "Level",
        selector: (row) => row.level,
        sortable: true,
        wrap: true,
      },
      {
        name: "Notification To",
        selector: (row) => row.notificationTo,
        sortable: true,
        wrap: true,
      },
      {
        name: "Mandatory",
        selector: (row) => (row.mandatory ? "Yes" : "No"),
        sortable: true,
        wrap: true,
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 1,
        center: true,
        width: "200px",
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="Edit"
                className="manage-event"
                onClick={() => editToggle(row)}
              >
                <EditIcon />
              </Button>
              <Button
                title="Delete"
                className="manage-event"
                onClick={() => deleteToggle(row.doc_type_id)}
              >
                <DeleteForeverIcon />
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );

  // dispatch add new document type
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let mandatory;
    if (data.get("mandatory") === "on") mandatory = true;
    else mandatory = false;

    const accountDataData = {
      document_type: data.get("docType"),
      level: data.get("level"),
      notificationTo: data.get("notificationTo"),
      mandatory: mandatory,
    };
    console.log("add doc type data", accountDataData);

    dispatch(addDocType(accountDataData, navigate, user.token));
  };

  // dispatch update document type
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let mandatory = false;
    if (data.get("mandatory") !== null) mandatory = true;
    else mandatory = false;

    const accountDataData = {
      document_type: data.get("docType"),
      level: data.get("level"),
      notificationTo: data.get("notificationTo"),
      doc_type_id: edit_doc_type.doc_type_id,
      mandatory: mandatory,
    };
    console.log("edit doc type data", accountDataData);

    dispatch(editDocType(accountDataData, user.token, navigate));
  };

  // dispatch delete document type
  const deleteDocType = (id) => {
    const delData = {
      doc_type_id: id,
    };
    console.log(delData);
    dispatch(delDocType(delData, navigate, user.token));
  };

  return (
    <div>
      <div className="page-heading-section">
        <div class="left-btn">
          <p className="event-page-heading">Document Types</p>
        </div>
        <div class="right-btn">
          <Button variant="contained" onClick={() => toggle()}>
            Add New
          </Button>
        </div>
      </div>
      <ToastContainer />

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className="hotel-locations-card-body">
            <DataTable
              columns={columns}
              theme=""
              data={docTypeList}
              highlightOnHover
              fixedHeader
              progressPending={loading}
              defaultSortAsc
              pagination
              sortIcon={<FilterListIcon />}
            />
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>New Document Type</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup col>
              <Label for="docType" sm={3}>
                Document Type
              </Label>
              <Col sm={12}>
                <Input id="docType" name="docType" required></Input>
              </Col>
            </FormGroup>

            <FormGroup col>
              <Label for="level" sm={3}>
                Level
              </Label>
              <Col sm={12}>
                <Input id="level" name="level" type="select" required>
                  <option>Event</option>
                  <option>Show</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup col>
              <Col sm={12}>
                <Input
                  id="mandatory"
                  name="mandatory"
                  type="checkbox"
                  style={{ verticalAlign: "baseline", marginRight: "10px" }}
                  sm={5}
                />

                <Label for="mandatory" sm={7}>
                  Mandatory
                </Label>
              </Col>
            </FormGroup>

            <FormGroup col>
              <Label for="docType" sm={3}>
                Notification To:
              </Label>
              <Col sm={12}>
                <Input
                  id="notificationTo"
                  name="notificationTo"
                  placeholder="Add email address or multiple ones, separated by commas"
                ></Input>
              </Col>
            </FormGroup>

            <Button fullWidth variant="contained" color="primary" type="submit">
              {loadingAddDocType ? "Adding..." : "Add New"}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={delModal} toggle={deleteToggle}>
        <ModalHeader toggle={deleteToggle}>Delete Entry</ModalHeader>
        <ModalBody>Are you sure you want to Delete this record?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteDocType(del_id)}>
            {loadingDelDocType ? "Deleting..." : "Yes, Delete"}
          </Button>{" "}
          <Button color="secondary" onClick={deleteToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editModal} toggle={editToggle}>
        <ModalHeader toggle={editToggle}>Edit Document Type</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleEditSubmit}>
            <FormGroup col>
              <Label for="docType" sm={3}>
                Document Type
              </Label>
              <Col sm={12}>
                <Input
                  id="docType"
                  name="docType"
                  defaultValue={edit_doc_type.document_type}
                  required
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup col>
              <Label for="level" sm={3}>
                Level
              </Label>
              <Col sm={12}>
                <Input
                  id="level"
                  name="level"
                  type="select"
                  defaultValue={edit_doc_type.level}
                  required
                >
                  <option>Event</option>
                  <option>Show</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup col>
              <Col sm={12}>
                <Input
                  id="mandatory"
                  name="mandatory"
                  defaultChecked={edit_doc_type.mandatory}
                  type="checkbox"
                  style={{ verticalAlign: "baseline", marginRight: "10px" }}
                  sm={5}
                />

                <Label for="mandatory" sm={7}>
                  Mandatory
                </Label>
              </Col>
            </FormGroup>

            <FormGroup col>
              <Label for="docType" sm={3}>
                Notification To:
              </Label>
              <Col sm={12}>
                <Input
                  id="notificationTo"
                  name="notificationTo"
                  defaultValue={edit_doc_type.notificationTo}
                  placeholder="Add email address or multiple ones, separated by commas"
                ></Input>
              </Col>
            </FormGroup>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {loadingEditDocType ? "Saving..." : "Save Changes"}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DocTypeList;
