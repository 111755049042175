import { Button, CardHeader, Form } from "reactstrap";

import React, { useState, useMemo } from "react";
import { Table } from "reactstrap";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Col,
  CardText,
  CardSubtitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useDispatch } from "react-redux";
import {
  saveSettings,
  getSettings,
  uploadFile,
  getDocs,
  delDoc,
} from "../store/adminSettings/actions";
import { useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
function AdminSettings() {
  const [fileUploadModal, setFileUploadModal] = useState(false);
  var file;
  const {
    saveSettingsResponse,
    loadingGetDocs,
    loadingDelDoc,
    loadingUploadFile,
    settings,
    loadingSaveSetting,
    docList,
    uploadFileRes,
    delDocResponse,
  } = useSelector((state) => state.AdminSettingsReducer);
  const { preDays, setPreDays } = useState();
  const [selectedType, setSelectedType] = useState();
  const { postDays, setPostDays } = useState();
  let dispatch = useDispatch();
  const [delModal, setDelModal] = useState(false);
  const [del_id, setDelId] = useState();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  console.log("in page", settings);
  const saveSettingsClick = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get("PreDays"), data.get("PostDays"));
    const settingsData = {
      preDays: data.get("PreDays"),
      postDays: data.get("PostDays"),
      archiveMonths: data.get("archiveMonths"),
    };

    dispatch(saveSettings(settingsData, user.token));
  };
  const toggleUpload = () => {
    setFileUploadModal(!fileUploadModal);
  };
  useEffect(() => {
    dispatch(getSettings(user.token));
  }, [saveSettingsResponse]);

  useEffect(() => {
    setFileUploadModal(false);
    setDelModal(false);

    dispatch(getDocs());
  }, [delDocResponse, uploadFileRes]);

  const deleteToggle = (del_id) => {
    setDelModal(!delModal);
    setDelId(del_id);
  };
  console.log("doc list", docList);
  //dispatch add new admin document
  const handleSubmitEventDoc = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    const data = new FormData(event.currentTarget);

    formData.append("docName", data.get("docName"));
    if (selectedType === "file") {
      formData.append("file", file);
    } else {
      formData.append("link", data.get("link"));
    }
    formData.append("staff_id", "");
    formData.append("docType", selectedType);
    formData.append("user_id", user.userData.user_id);

    console.log("document to upload", formData);
    dispatch(uploadFile(formData, navigate, user.token));
  };
  // handle change event of input file
  function handleChange(e) {
    console.log("file", e.target.id);
    const files = Array.from(e.target.files);

    file = files[0];
    console.log(file);
    //setFile({doc_type_id:e.target.id,doc_type_file:files[0]});
  }

  // dispatch delete admin document
  const deleteDoc = (id) => {
    const delData = {
      document_id: id,
    };
    console.log(delData);
    dispatch(delDoc(delData, user.token));
  };
  //columns for the list of admin documents
  const columns = useMemo(
    () => [
      {
        name: "Document Name",
        selector: (row) => row.docName,
        sortable: true,
        wrap: true,
        grow: 1,
      },

      {
        name: "File",
        selector: (row) =>
          row.docType === "file" ? row?.data_file?.originalname : row.link,
        sortable: true,
        wrap: true,
        grow: 3,
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="View"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                href={
                  row.docType === "file"
                    ? process.env.REACT_APP_APP_URL +
                      "api/v1/adminDocuments?document_id=" +
                      row._id
                    : row.link
                }
              >
                <VisibilityIcon />
              </Button>
              <Button
                title="Delete"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                onClick={() => deleteToggle(row._id)}
              >
                <DeleteForeverIcon />
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );
  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">Admin Settings</p>

      <div className="page-body">
        <Card className="my-2">
          <CardHeader>
            {" "}
            <b>Manage Cutoff</b>
          </CardHeader>
          <CardBody>
            <Form sm={6} onSubmit={saveSettingsClick}>
              <FormGroup col>
                <Label for="PreDays" sm={3}>
                  Pre Cutoff Days :
                </Label>
                <Input
                  name="PreDays"
                  type="number"
                  onChange={setPreDays}
                  defaultValue={settings ? settings.preDays : 0}
                  placeholder="Pre cutoff number of days"
                />
              </FormGroup>
              <FormGroup col>
                <Label for="PostDays" sm={3}>
                  Post Cutoff Days :
                </Label>
                <Input
                  name="PostDays"
                  type="number"
                  onChange={setPostDays}
                  defaultValue={settings ? settings.postDays : 0}
                  placeholder="Post cutoff number of days"
                />
              </FormGroup>
              <FormGroup row>
                <Label for="archiveMonths" sm={3}>
                  Archive & Delete Events After :
                </Label>
                <Input
                  style={{ width: "70px" }}
                  name="archiveMonths"
                  type="number"
                  defaultValue={
                    settings?.archiveMonths ? settings.archiveMonths : 24
                  }
                />
                <Label for="archiveMonths" sm={3}>
                  Months
                </Label>
              </FormGroup>
              <Button variant="contained" color="primary" type="submit">
                {loadingSaveSetting ? "Saving..." : "Save"}
              </Button>
            </Form>
          </CardBody>
        </Card>

        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <b>Manage Documents</b>
            <Button
              title="Upload"
              className="manage-event"
              onClick={() => toggleUpload()}
              style={{ float: "right" }}
            >
              Add New Document
              <FileUploadIcon />
            </Button>
          </CardHeader>

          <CardBody className="event-card-body">
            <DataTable
              columns={columns}
              data={docList}
              theme=""
              pagination
              progressPending={loadingGetDocs}
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              highlightOnHover
              fixedHeader
            />
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={fileUploadModal} toggle={toggleUpload}>
        <ModalHeader toggle={toggleUpload}>Upload New Document</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitEventDoc}>
            <FormGroup col>
              <Input
                name="docName"
                type="text"
                placeholder="Document Name"
                required
              />
            </FormGroup>
            <FormGroup col>
              <Input
                name="type"
                type="select"
                defaultValue={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                placeholder="Document Name"
                required
              >
                <option selected disabled>
                  Select Document Type
                </option>

                <option value={"file"}>Upload a file</option>
                <option value={"url"}>Add file link</option>
              </Input>
            </FormGroup>

            {selectedType === "file" ? (
              <FormGroup col>
                <Col sm={12}>
                  <Input
                    name="documentFile"
                    type="file"
                    onChange={handleChange}
                    required
                  />
                </Col>
              </FormGroup>
            ) : (
              ""
            )}
            {selectedType === "url" ? (
              <FormGroup col>
                <Input
                  name="link"
                  type="text"
                  placeholder="Document URL"
                  required
                />
              </FormGroup>
            ) : (
              ""
            )}

            <Button fullWidth variant="contained" color="primary" type="submit">
              {loadingUploadFile ? "Uploading..." : "Submit"}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={delModal} toggle={deleteToggle}>
        <ModalHeader toggle={deleteToggle}>Delete Document</ModalHeader>
        <ModalBody>Are you sure you want to Delete this document?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteDoc(del_id)}>
            {loadingDelDoc ? "Deleting..." : "Yes, Delete"}
          </Button>{" "}
          <Button color="secondary" onClick={deleteToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdminSettings;
