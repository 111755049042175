import { takeLatest, put, call } from "redux-saga/effects";

import { GET_SETTINGS,SAVE_SETTINGS,UPLOAD_FILE,GET_DOCS,DEL_DOC} from "./actionTypes";

import {
  getSettingsFail,
  getSettingsSuccess,
  saveSettingsFail,
  saveSettingsSuccess,
  uploadFileFailure,
  uploadFileSuccess,
  getDocsFail,
  getDocsSuccess,
  delDocFailure,
  delDocSuccess
  
} from "./actions";

import { getSettings,saveSettings,uploadFile,getAdminDocs,delAdminDoc,updateFileUploadLogs} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* onDelDoc({ payload: data,token }) {

  
  try {
    const response = yield call(delAdminDoc,data,token);
    console.log(response);
    yield put(delDocSuccess(response.data));
  } catch (error) {
    yield put(delDocFailure(error.response));
  }
}
function* onGetDocs() {

  
  try {
    const response = yield call(getAdminDocs);
    // console.log(response);
  
    yield put(getDocsSuccess(response.data));
  } catch (error) {
    yield put(getDocsFail(error.response.data));
  }
}

function* onGetSettings({token}) {

  
  try {
    const response = yield call(getSettings,token);
    console.log(response);
  
    yield put(getSettingsSuccess(response.data));
  } catch (error) {
    yield put(getSettingsFail(error.response.data));
  }
}

function* onSaveSettings({ payload: data,token }) {

  
  try {
    const response = yield call(saveSettings,data,token);
   // console.log(response);
    toast(response.message);
    yield put(saveSettingsSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(saveSettingsFail(error.response));
  }
}

function* onUploadFile({ payload: data,token,navigate }) {
  // console.log("onUploadFile >> data >> ",data);
  let originalFileName = '';
  let fileExtension = '';

  // Extract file details from FormData
  data.forEach((value, key) => {
    if (value instanceof File) {
      originalFileName = value.name;
      fileExtension = value.name.split('.').pop();
    }
  });

  // Prepare JSON payload with extracted file details
  const formDataObj = {
    docType: data.get("docType"),
    staff_id: data.get("staff_id"),
    docType: data.get("docType"),
    user_id: data.get("user_id"),
    originalFileName, // Add extracted file details directly
    fileExtension,    // Add extracted file details directly
  };

  // console.log("FormData contents as JSON: ", formDataObj);

  try {
    const response = yield call(uploadFile,data,token);
    // console.log("onUploadFile >> ",response);
    const filelogsId = response?.fileslogId;
    if (filelogsId) {
      // console.log("filelogsId >> 11 >> ", filelogsId);
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    toast(response.message);
      yield put(uploadFileSuccess(response.data));
  } catch (error) {
    const filelogsId = error?.response?.data?.fileslogId;
    if (filelogsId) {
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    // toast(error.response.data.message);
    yield put(uploadFileFailure(error.response));
  }
}

function* SettingsSaga() {

  yield takeLatest(SAVE_SETTINGS, onSaveSettings);
  yield takeLatest(GET_SETTINGS, onGetSettings);
  yield takeLatest(UPLOAD_FILE, onUploadFile);
  yield takeLatest(GET_DOCS, onGetDocs);
  yield takeLatest(DEL_DOC, onDelDoc);

}

export default SettingsSaga;
