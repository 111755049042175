import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import logo from "../assets/logo.png";

import { tryLogin } from "../store/auth/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { login } = useAuth();
  const { loginResponse, loading } = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();

  let dispatch = useDispatch();

  // dispatch login
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    console.log("login data", loginData);

    dispatch(tryLogin(loginData, login));
  };

  return (
    <Container component="main" maxWidth="100%" className="login-body">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "white",
            padding: "40px",
            width: "fit-content",
          }}
        >
          <img src={logo} alt="Logo" width={300} />
          <div className="divider-login" />
          <Typography component="h1" variant="h6" fontWeight="bold">
            Results Submission Login
          </Typography>
          <Typography fontSize={12}>Enter your login information</Typography>
          <Box
            component="form"
            width={350}
            boxSizing="sm"
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              variant="filled"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="filled"
            />
            <Button onClick={() => navigate("/forgot-password")}>
              Forgot Password?
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
            >
              {loading ? "Loading..." : "Log In"}
            </Button>
            <ToastContainer />
            <div className="version-info">
              <span>Version 1.0.10</span>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
