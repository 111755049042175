import React, { useEffect, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Row,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
} from "reactstrap";
import { CSVLink, CSVDownload } from "react-csv";
import Button from "@mui/material/Button";

import "../styles.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation } from "react-router-dom";
import { getMarkedCatalog } from "../helpers/backend_helper";
import { useNavigate } from "react-router-dom";

function MarkedCatalog() {
  const location = useLocation();
  const navigate = useNavigate();
  const [markedList, setMarkedList] = useState([]);
  const [loading, setLoading] = useState(true);
  let allBreads = [],
    allGroups = [];
  if (markedList) {
    allBreads = [...new Set(markedList.map((item) => item.BreedName))];
    allGroups = [...new Set(markedList.map((item) => item.GroupName))];
  }
  var exportData = [];

  // get the marked data using canuck api
  const getMarked = async () => {
    setLoading(true);
    let response = await getMarkedCatalog(location.state.event.ckc_number);
    console.log("in res", response);
    setMarkedList(response.data.cat_data);
    setLoading(false);
  };
  useEffect(() => {
    getMarked();
  }, []);


  // create export file of marked catalog
  if (allGroups.length > 0) {
    let eventData = [
      {
        1: markedList[0].ShowName,
        2: "",
      },
      {
        1: "Event No:",
        2: location.state.event.ckc_number,
      },
      {
        1: "Date:",
        2: markedList[0].EventDate,
      },
      {
        1: "Description",
        2: markedList[0].EventName,
      },
      {
        1: "BIS Judge",
        2: markedList[0].BestInShowJudge,
      },
      {
        1: "",
        2: "",
      },
      {
        1: "",
        2: "",
      },
    ];
    exportData = exportData.concat(eventData);
    //console.log(allGroups);
    for (var j = 0; j < allGroups.sort().length; j++) {
      let groupWiseList = markedList.filter((obj) => {
        return obj.GroupName === allGroups[j];
      });
      let allBreadsInOneGroup = [
        ...new Set(groupWiseList.map((item) => item.BreedName)),
      ];
      console.log(allGroups[j], allBreadsInOneGroup);
      let list = [
        {
          1: allGroups[j] + "- Judge: " + groupWiseList[0].GroupJudge,
          2: groupWiseList[0].BOBCount,
          3: "",
          4: "",
          5: "",
          6: "",
        },
      ];
      exportData = exportData.concat(list);

      for (var i = 0; i < allBreadsInOneGroup.sort().length; i++) {
        var ordering = {};
        let sortOrderList = [
          "WD",
          "RWD",
          "WB",
          "RWB",
          "BOW",
          "BOB",
          "BOS",
          "BPIB",
          "SD",
          "SB",
        ];

        for (var k = 0; k < sortOrderList.length; k++) {
          ordering[sortOrderList[k]] = k;
        }

        let tempList = groupWiseList.filter((obj) => {
          return obj.BreedName === allBreadsInOneGroup[i];
        });

        tempList = tempList.sort(function (a, b) {
          return (
            ordering[a.Wins] - ordering[b.Wins] ||
            a.DogName.localeCompare(b.DogName)
          );
        });

        let TCN = tempList[0].TCN === null ? 0 : tempList[0].TCN;
        let ABS = tempList[0].Absent === null ? 0 : tempList[0].Absent;
        let DQ = tempList[0].DQ === null ? 0 : tempList[0].DQ;
        let EXC = tempList[0].Excused === null ? 0 : tempList[0].Excused;
        let list = [
          {
            1: allBreadsInOneGroup[i],
            2:
              "[" +
              tempList[0].ClassMales +
              "-" +
              tempList[0].ClassFemales +
              "-" +
              tempList[0].SpecialMales +
              "-" +
              tempList[0].SpecialFemales +
              "]",
            3:
              "[" +
              TCN +
              " TCN - " +
              ABS +
              " ABS - " +
              DQ +
              " DQ - " +
              EXC +
              " Exc]",
            4: "",
            5: "",
            6: "",
          },
        ];
        exportData = exportData.concat(list);

        let breedWiseList = tempList.map((item) => ({
          1: "",
          2: item.Wins,
          3: item.DogCKCNumber,
          4: item.DogName,
          5: item.ArmBand,
          6: item.ClassName,
        }));

        exportData = exportData.concat(breedWiseList);
      }
    }

    console.log("final export", exportData);
  }

  // columns for the list of marked catalog
  const columns = useMemo(
    () => [
      {
        name: "GroupName",
        selector: (row) => row.GroupName,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "BreedName",
        selector: (row) => row.BreedName,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "ClassName",
        selector: (row) => row.ClassName,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "ArmBand",
        selector: (row) => row.ArmBand,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Dog Name",
        selector: (row) => row.DogName,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "DogCKCNumber",
        selector: (row) => row.DogCKCNumber,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Wins",
        selector: (row) => row.Wins,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "TCN",
        selector: (row) => row.TCN,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Absent",
        selector: (row) => row.Absent,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "DQ",
        selector: (row) => row.DQ,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Excused",
        selector: (row) => row.Excused,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Puppies",
        selector: (row) => row.Puppies,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "AltMales",
        selector: (row) => row.AltMales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "AltFemales",
        selector: (row) => row.AltFemales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "ClassMales",
        selector: (row) => row.ClassMales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "ClassFemales",
        selector: (row) => row.ClassFemales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "SpecialMales",
        selector: (row) => row.SpecialMales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "SpecialFemales",
        selector: (row) => row.SpecialFemales,
        sortable: true,
        wrap: true,
        grow: 2,
      },
    ],
    []
  );

  const moveBack = () => {
    navigate("/event-list", {
      state: { master_event: location.state.master_event },
    });
  };

  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">
        Marked Catalog
        <nbsp />
        {exportData && exportData.length > 0 ? (
          <CSVLink
            className="button-style"
            style={{ float: "right", marginRight: "10px", fontSize: "16px" }}
            data={exportData}
            filename={"marked_catalog_" + location.state.event.ckc_number}
          >
            Export
          </CSVLink>
        ) : (
          ""
        )}
        <button
        className="button-style"
        style={{
          float: "right",
          marginRight: "10px",
          fontSize: "16px"
          }}
          onClick={moveBack}
        >
        Back
        </button>
      </p>

      <ToastContainer />

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          {markedList && markedList.length > 0 ? (
            <CardHeader>
              <CardTitle>{markedList[0].EventName}</CardTitle>
              <CardSubtitle>
                Event No: {location.state.event.ckc_number}
              </CardSubtitle>
              <CardText>Date: {markedList[0].EventDate}</CardText>
              <CardText>BIS Judge: {markedList[0].BestInShowJudge}</CardText>
            </CardHeader>
          ) : (
            ""
          )}
          <CardBody className="event-card-body">
            <DataTable
              columns={columns}
              data={markedList}
              theme=""
              pagination
              progressPending={loading}
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              highlightOnHover
              fixedHeader
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default MarkedCatalog;
