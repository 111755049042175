import { Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDogs, activateAccount } from "../../store/secretaries/actions";
import "../../styles.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";
import Button from "@mui/material/Button";

import { useAuth } from "../../hooks/useAuth";
import TextField from "@mui/material/TextField";
import { Routes, Route, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { findSecretary } from "../../helpers/backend_helper";
function AddNewSecretary() {
  const { user, logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [secList, setSecList] = useState([]);
  const [selectedSecData, setSelectedSecList] = useState();
  const [isFindingSec, setIsFindingSec] = useState();

  const toggle = (row) => {
    setModal(!modal);
    setSelectedSecList(row);
  };

  const navigate = useNavigate();
  const { loading, loadingActivateAccount } = useSelector(
    (state) => state.SecretatiesReducer
  );

  if (user.userData.role === "secretary") {
    navigate("/event-list");
  }

  // columns to show
  const columns = useMemo(
    () => [
      {
        name: "CKC ID",
        selector: (row) => row.ID_NO,
        sortable: true,
        wrap: true,
        grow: 1,
      },
      {
        name: "User Name",
        selector: (row) => row.SecretaryName,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Account",
        sortable: true,
        wrap: true,
        cell: (row) => (
          <Row>
            <p
              title="View"
              className="manage-event"
              onClick={() => toggle(row)}
            >
              Add Now
            </p>
          </Row>
        ),
      },
    ],
    []
  );

  // find secretary by name using canuck dogs api at backend
  const searchSubmit = async (event) => {
    event.preventDefault();
    setIsFindingSec(true);
    const data = new FormData(event.currentTarget);
    const searchText = data.get("name");
    console.log("search text", searchText);
    let response = await findSecretary(searchText);
    console.log("in res", response);
    setSecList(response.sec_list);
    setIsFindingSec(false);
  };

  let dispatch = useDispatch();

  // dispatch activate secretary account
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const accountDataData = {
      email: data.get("email"),
      name: selectedSecData.SecretaryName,
      password: "12345",
      role: "secretary",
      secretary_canuk_id: selectedSecData.ID_NO,
    };
    console.log("activate account data", accountDataData);

    dispatch(activateAccount(accountDataData, navigate));
  };

  return (
    <div>
      <div className="page-heading-section">
        <div class="left-btn">
          <p className="event-page-heading">Add New Secretary</p>
        </div>
        <div class="right-btn"></div>
      </div>
      <ToastContainer />

      <div className="page-body">
        <Box component="form" onSubmit={searchSubmit}>
          <Row>
            <Col sm="8">
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Search Canuck Secretary By Name"
                name="name"
                autoFocus
                variant="filled"
                className="search_field_sec"
              />
            </Col>

            <Col sm="4">
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className="search_button_sec"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Box>

        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className="hotel-locations-card-body">
            <DataTable
              columns={columns}
              data={secList}
              pagination
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              progressPending={isFindingSec}
              highlightOnHover
              fixedHeader
              sortIcon={<FilterListIcon />}
            />
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <Box component="form" onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Add New Staff User</ModalHeader>
          <ModalBody>
            Please enter Email Address to activate this account.
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Secretary Email ID"
              name="email"
              autoComplete="email"
              autoFocus
              variant="filled"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {loadingActivateAccount ? "Loading..." : "Activate Now"}
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Box>
      </Modal>
    </div>
  );
}

export default AddNewSecretary;
