import { Button } from "reactstrap";
import React from "react";
import { Table } from "reactstrap";
import { Card, CardBody, CardTitle, CardText, CardSubtitle } from "reactstrap";
import { useSelector } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function MyAccount() {
  const { loginResponse, loading } = useSelector((state) => state.AuthReducer);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">My Profile</p>

      <div className="page-body">
        <Card
          style={{
            width: "18rem",
          }}
        >
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              {user.adminData.email}
            </CardSubtitle>

            <Button onClick={() => navigate("/dashboard/change-password")}>
              Change Password
            </Button>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default MyAccount;
