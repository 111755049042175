import {
    GET_USER_LOGS,
    GET_USER_LOGS_SUCCESS,
    GET_USER_LOGS_FAIL,
    GET_File_UPLOAD_LOGS,
    GET_File_UPLOAD_LOGS_FAIL,
    GET_File_UPLOAD_LOGS_SUCCESS,
    UPDATE_FILE_UPLOAD_LOGS,
  } from "./actionTypes";
  
  // Action to initiate fetching user logs
  export const getUserLogs = () => {
    return {
      type: GET_USER_LOGS,
    };
  };
  
  // Action for successful fetching of user logs
  export const getUserLogsSuccess = (logs) => {
    return {
      type: GET_USER_LOGS_SUCCESS,
      payload: logs.userLogs,
    };
  };
  
  // Action for failed fetching of user logs
  export const getUserLogsFail = (error) => {
    return {
      type: GET_USER_LOGS_FAIL,
      payload: error,
    };
  };

  export const getFileUploadLogs = () => {
    return {
      type: GET_File_UPLOAD_LOGS,
    };
  };
  
  // Action for successful fetching of user logs
  export const getFileUploadLogsSuccess = (logs) => {
    return {
      type: GET_File_UPLOAD_LOGS_SUCCESS,
      payload: logs.fileUploadLogs,
    };
  };
  
  // Action for failed fetching of user logs
  export const getFileUploadLogsFail = (error) => {
    return {
      type: GET_File_UPLOAD_LOGS_FAIL,
      payload: error,
    };
  };

  export const updateFileUploadLogs = (data, token) => {
    return {
      type: UPDATE_FILE_UPLOAD_LOGS,
      payload: { data, token },
    };
  };
  
  // Action for successful fetching of user logs
  export const updateFileUploadLogsSuccess = (updateFileUploadLogs) => {
    return {
      type: GET_File_UPLOAD_LOGS_SUCCESS,
      payload: updateFileUploadLogs,
    };
  };
  
  // Action for failed fetching of user logs
  export const updateFileUploadLogsFail = (error) => {
    return {
      type: GET_File_UPLOAD_LOGS_FAIL,
      payload: error,
    };
  };
  