import {
  GET_DOGS,
  GET_DOGS_SUCCESS,
  GET_DOGS_FAIL,
 ACTIVATE_ACCOUNT,
 ACTIVATE_ACCOUNT_FAILURE,
 ACTIVATE_ACCOUNT_SUCCESS,
 RESET_USER_PASSWORD,
 RESET_USER_PASSWORD_SUCCESS,
 RESET_USER_PASSWORD_FAILURE,
 DEL_USER,
 DEL_USER_FAILURE,
 DEL_USER_SUCCESS
} from "./actionTypes";

const initialState = {
  dogList: [],
  loading: false,
  error: {
    message: "",
    code:"",
  },
 
  activateAccountResponse: {},
  loadingActivateAccount: false,
  resetUserPasswordResponse: {},
  loadingResetUserPassword: false,
  loadingDelUser:false,
  delUserResponse:{},

};

const SecretatiesReducer = (state = initialState, action) => {


  switch (action.type) {

    case GET_DOGS:
      state = { ...state, loading: true };
      break;
    case GET_DOGS_SUCCESS:
      console.log("action data",action);
      state = { ...state, dogList: action.payload.userList, loading: false };
      break;
    case GET_DOGS_FAIL:
      state = {
        ...state,
        error: {
          
        },
        loading: false,
      };
      break;
     
    case ACTIVATE_ACCOUNT:
      state = { ...state, loadingActivateAccount: true };
      break;
    case ACTIVATE_ACCOUNT_SUCCESS:
      state = { ...state, activateAccountResponse: action.payload, loadingActivateAccount: false };

      break;
    case ACTIVATE_ACCOUNT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingActivateAccount: false,
      };
      break;

    case RESET_USER_PASSWORD:
      state = { ...state, loadingResetUserPassword: true };
      break;
    case RESET_USER_PASSWORD_SUCCESS:
      state = { ...state, resetUserPasswordResponse: action.payload, loadingResetUserPassword: false };

      break;
    case RESET_USER_PASSWORD_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingResetUserPassword: false,
      };
      break;
        
    case DEL_USER:
      state = { ...state, loadingDelUser: true };
      break;
    case DEL_USER_SUCCESS:
      state = { ...state, delUserResponse: action.payload, loadingDelUser: false };

      break;
    case DEL_USER_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelUser: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SecretatiesReducer;
