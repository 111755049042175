import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  SAVE_SETTINGS,
  SAVE_SETTINGS_FAILURE,
  SAVE_SETTINGS_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  GET_DOCS,
  GET_DOCS_SUCCESS,
  GET_DOCS_FAIL,
  DEL_DOC,
  DEL_DOC_FAILURE,
  DEL_DOC_SUCCESS,
} from "./actionTypes";

export const saveSettings= (data,token) => {
  return {
    type: SAVE_SETTINGS,
    token:token,
    payload:data,
  };
};

export const delDoc = (data,token) => {
  return {
    type: DEL_DOC,
    payload: data,
    token:token
  };
};

export const delDocSuccess = (response) => {
  return {
    type: DEL_DOC_SUCCESS,
    payload:response,
  };
};

export const delDocFailure = (error) => {
  return {
    type: DEL_DOC_FAILURE,
    payload:error,
  };
};

export const saveSettingsSuccess = (response) => {
  return {
    type:SAVE_SETTINGS_SUCCESS,
    payload: response,
  };
};

export const saveSettingsFail = (error) => {
  return {
    type:SAVE_SETTINGS_FAILURE,
    payload: error,
  };
};


export const getSettings= (token) => {
  return {
    type: GET_SETTINGS,
    token:token
  };
};

export const getSettingsSuccess = (settings) => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const getSettingsFail = (error) => {
  return {
    type:GET_SETTINGS_FAIL,
    payload: error,
  };
};


export const uploadFile = (data,navigate,token) => {
  return {
    type: UPLOAD_FILE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const uploadFileSuccess = (res) => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload:res,
  };
};

export const uploadFileFailure = (error) => {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload:error,
  };
};


export const getDocs= () => {
  return {
    type: GET_DOCS,
  };
};

export const getDocsSuccess = (docList) => {
  return {
    type: GET_DOCS_SUCCESS,
    payload: docList,
  };
};

export const getDocsFail = (error) => {
  return {
    type:GET_DOCS_FAIL,
    payload: error,
  };
};
