import { Row } from "reactstrap";
import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getDogs,
  activateAccount,
  resetUserPassword,
  delUser,
} from "../store/secretaries/actions";
import "../styles.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";
import Button from "@mui/material/Button";

import { useAuth } from "../hooks/useAuth";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Routes, Route, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import PasswordIcon from "@mui/icons-material/Password";
function AllSecretaries() {
  const { user, logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(false);

  const [secList, setSecList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [del_id, setDelId] = useState();
  const toggle = () => {
    setModal(!modal);
  };

  const passwordToggle = (email) => {
    setResetPasswordEmail(email);
    setPasswordModal(!passwordModal);
  };
  const navigate = useNavigate();
  const {
    dogList,
    loading,
    loadingActivateAccount,
    loadingResetUserPassword,
    loadingDelUser,
  } = useSelector((state) => state.SecretatiesReducer);

  if (user.userData.role === "secretary") {
    navigate("/event-list");
  }

  const UserToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const deleteToggle = (del_id) => {
    setDelModal(!delModal);
    setDelId(del_id);
  };

  const columns = useMemo(
    () => [
      {
        name: "CKC ID",
        selector: (row) =>
          row.role !== "staff" ? row.secretary_canuk_id : "NA",
        sortable: true,
        wrap: true,
        grow: 1,
      },
      {
        name: "User Name",
        sortable: true,
        id: "username",
        wrap: true,
        grow: 2,
        selector: (row) =>
          row.role === "staff" ? row.staff_name : row.secretary_name,
      },

      {
        name: "Role",
        selector: (row) => row.role,
        sortable: true,
        wrap: true,
      },
      {
        name: "Email/Account",
        sortable: true,
        selector: (row) => row.email,
        wrap: true,
      },
      {
        name: "Events",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            {row.role === "staff" ? (
              <div>----</div>
            ) : (
              <p
                title="View"
                className="manage-event"
                onClick={() => viewSecEvents(row.index, row)}
              >
                View
              </p>
            )}
          </Row>
        ),
      },
      {
        name: "Password",
        ignoreRowClick: true,
        cell: (row) => (
          <Row>
            {user.userData.role === "staff" && row.role === "staff" ? (
              <div>----</div>
            ) : (
              <Button
                title="Reset Password"
                onClick={() => passwordToggle(row.email)}
              >
                <PasswordIcon />
              </Button>
            )}
          </Row>
        ),
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 1,
        center: true,
        cell: (row) => (
          <Row>
            <Col>
              {user.userData.role === "staff" && row.role === "staff" ? (
                ""
              ) : (
                <Button
                  title="Delete"
                  className="manage-event"
                  onClick={() => deleteToggle(row.user_id)}
                >
                  <DeleteForeverIcon />
                </Button>
              )}
            </Col>
          </Row>
        ),
      },

      {
        name: "Last Login",
        sortable: true,
        selector: (row) =>
          new Intl.DateTimeFormat("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(row.updated_at),

        wrap: true,
      },
    ],
    []
  );

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDogs(user.token));
  }, []);

  const viewSecEvents = (index, row) => {
    navigate("/event-list?id=" + row.user_id, {
      state: { index: index, data: row },
    });
  };

  const moveToAddSecretary = () => {
    navigate("/add-new-secretary");
  };

  // dispatch activate staff account
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const accountDataData = {
      email: data.get("email"),
      name: data.get("staffName"),
      password: "12345",
      role: "staff",
      secretary_canuk_id: "",
    };
    console.log("activate account data", accountDataData);

    dispatch(activateAccount(accountDataData, navigate));
  };

  // dispatch delete any user(sec or staff)
  const deleteUser = (id) => {
    const delData = {
      user_id: id,
    };
    console.log(delData);
    dispatch(delUser(delData, navigate, user.token));
  };

  // change password of any user
  const handleResetPassword = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const accountDataData = {
      password: data.get("password"),
      email: resetPasswordEmail,
    };
    console.log("activate account data", accountDataData);

    dispatch(resetUserPassword(accountDataData, navigate));
  };
  return (
    <div>
      <div className="page-heading-section">
        <div class="left-btn">
          <p className="event-page-heading">All Users</p>
        </div>
        <div class="right-btn">
          <ButtonDropdown
            varianActiont="contained"
            isOpen={dropdownOpen}
            toggle={UserToggle}
          >
            <DropdownToggle caret>Add New User</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => moveToAddSecretary()}>
                Add Secretary
              </DropdownItem>
              <DropdownItem divider />
              {user.userData.role === "admin" ? (
                <DropdownItem onClick={toggle}>Add Staff</DropdownItem>
              ) : (
                <div></div>
              )}
            </DropdownMenu>
          </ButtonDropdown>

          {/* <Button variant="contained" >Add New User</Button> */}
        </div>
      </div>
      <ToastContainer />

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          <CardBody className="hotel-locations-card-body">
            <DataTable
              columns={columns}
              data={dogList}
              pagination
              defaultSortFieldId={"username"}
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              progressPending={loading}
              highlightOnHover
              fixedHeader
              sortIcon={<FilterListIcon />}
            />
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <Box component="form" onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Add New Staff User</ModalHeader>
          <ModalBody>
            <TextField
              margin="normal"
              required
              fullWidth
              id="staffName"
              label="Staff User Name"
              name="staffName"
              autoFocus
              variant="filled"
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Staff Email ID"
              name="email"
              autoComplete="email"
              autoFocus
              variant="filled"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {loadingActivateAccount ? "Loading..." : "Add User"}
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Box>
      </Modal>
      <Modal isOpen={passwordModal} toggle={passwordToggle}>
        <Box component="form" onSubmit={handleResetPassword}>
          <ModalHeader toggle={passwordToggle}>Change Password</ModalHeader>
          <ModalBody>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New Password"
              name="password"
              autoFocus
              variant="filled"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {loadingResetUserPassword ? "Loading..." : "Set New Password"}
            </Button>{" "}
            <Button color="secondary" onClick={passwordToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Box>
      </Modal>
      <Modal isOpen={delModal} toggle={deleteToggle}>
        <ModalHeader toggle={deleteToggle}>Delete Entry</ModalHeader>
        <ModalBody>Are you sure you want to delete this record?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteUser(del_id)}>
            {loadingDelUser ? "Deleting..." : "Yes, Delete"}
          </Button>{" "}
          <Button color="secondary" onClick={deleteToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AllSecretaries;
