export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

export const SAVE_SETTINGS="SAVE_SETTINGS";
export const SAVE_SETTINGS_SUCCESS="SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_FAILURE="SAVE_SETTINGS_FAILURE";



export const UPLOAD_FILE="UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS="UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE="UPLOAD_FILE_FAILURE";
export const GET_DOCS = "GET_ADMIN_DOCS";
export const GET_DOCS_SUCCESS = "GET_ADMIN_DOCS_SUCCESS";
export const GET_DOCS_FAIL = "GET_ADMIN_DOCS_FAIL";
export const DEL_DOC="DEL_ADMIN_DOC";
export const DEL_DOC_SUCCESS="DEL_ADMIN_DOC_SUCCESS";
export const DEL_DOC_FAILURE="DEL_ADMIN_DOC_FAILURE";
