import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import React, { useEffect, useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
    Col,
    Row,
    Form,
    Card,
    ButtonGroup,
    FormText,
    FormGroup,
    Label,
    Input,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import "../../styles.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserLogs } from '../../store/logs/actions';

const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    
    // Define options for date and time formatting
    const options = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true // Use 24-hour format
    };
    
    // Create a formatter for the specified time zone
    const formatter = new Intl.DateTimeFormat('en-US', options);
    
    // Format the date and time
    const formattedDateTime = formatter.format(date);
    
    return formattedDateTime;
};

export const UserLogs = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const columns = useMemo(
        () => [
            {
                name: "Email",
                selector: (row) => row.email,
                sortable: true,
                wrap: true,
            },
            {
                name: "Role",
                selector: (row) => row.role,
                sortable: true,
                wrap: true,
            },
            {
                name: "Name",
                selector: (row) => row.role == 'admin' ? 'ckc-admin' : row.secretary_name ? row.secretary_name : (row.staff_name ? row.staff_name : '-'),
                sortable: true,
                wrap: true,
            },
            {
                name: "Login time",
                selector: (row) => row.login_time ? formatDate(row.login_time) : '-',
                sortable: true,
                wrap: true,
            },
            {
                name: "Logout time",
                selector: (row) => row.logout_time ? formatDate(row.logout_time) : '-',
                sortable: true,
                wrap: true,
            }
        ],
        []
    );

    useEffect(() => {
        dispatch(getUserLogs());
    }, [dispatch]);

    // Access logs and loading state from Redux store
    const { userLogs, loading, error } = useSelector((state) => {
        // console.log("state >> ", state);
        return state.LogsReducer || {}
    });

    // console.log("userLogs >> ", userLogs);
    // console.log("loading >> ", loading);
    // console.log("error >> ", error);

    return (
        <div>
            <div className="page-heading-section">
                <div class="left-btn">
                    <p className="event-page-heading">User Logs</p>
                </div>
            </div>
            <ToastContainer />

            <div className="page-body">
                <Card className="my-2" style={{ padding: 0 }}>
                    <CardBody className="hotel-locations-card-body">
                        <DataTable
                            columns={columns}
                            theme=""
                            data={userLogs}
                            highlightOnHover
                            fixedHeader
                            progressPending={loading}
                            defaultSortAsc
                            pagination
                            sortIcon={<FilterListIcon />}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};
