import {
  GET_DOC_TYPES,
  GET_DOC_TYPES_SUCCESS,
  GET_DOC_TYPES_FAIL,
  DEL_DOC_TYPE,
  DEL_DOC_TYPE_FAILURE,
  DEL_DOC_TYPE_SUCCESS,
  EDIT_DOC_TYPE,
  EDIT_DOC_TYPE_SUCCESS,
  EDIT_DOC_TYPE_FAILURE,
  ADD_DOC_TYPE,
  ADD_DOC_TYPE_FAILURE,
  ADD_DOC_TYPE_SUCCESS
} from "./actionTypes";

const initialState = {
  docTypeList: [],
  loading: false,
  error: {
    message: "",
    code:"",
  },
  delDoCTypeResponse: {},
  loadingDelDocType: false,

  editDoCTypeResponse: {},
  loadingEditDocType: false,
  addDocTypeResponse: {},
  loadingAddDocType: false,

};

const DocTypesReducer = (state = initialState, action) => {


  switch (action.type) {
    case ADD_DOC_TYPE:
      state = { ...state, loadingAddDocType: true };
      break;
    case ADD_DOC_TYPE_SUCCESS:
      state = { ...state, addDocTypeResponse: action.payload, loadingAddDocType: false };

      break;
    case ADD_DOC_TYPE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddDocType: false,
      };
      break;
    case GET_DOC_TYPES:
      state = { ...state, loading: true };
      break;
    case GET_DOC_TYPES_SUCCESS:
      state = { ...state, docTypeList: action.payload.docTypesList, loading: false };
      break;
    case GET_DOC_TYPES_FAIL:

      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loading: false,
      };
      break;
      
    case DEL_DOC_TYPE:
      state = { ...state, loadingDelDocType: true };
      break;
    case DEL_DOC_TYPE_SUCCESS:
      state = { ...state, delDoCTypeResponse: action.payload, loadingDelDocType: false };

      break;
    case DEL_DOC_TYPE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelDocType: false,
      };
      break;

      case EDIT_DOC_TYPE:
        state = { ...state, loadingEditDocType: true };
        break;
      case EDIT_DOC_TYPE_SUCCESS:
        state = { ...state, editDoCTypeResponse: action.payload, loadingEditDocType: false };
  
        break;
      case EDIT_DOC_TYPE_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingEditDocType: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DocTypesReducer;
