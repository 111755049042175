import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import store from "./store/index";
import persistor from "./store/index";
// import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";

import MyAccount from "./pages/MyAccount";

import { SetNewPassword } from "./pages/SetNewPassword";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ChangePassword } from "./pages/ChangePassword";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AddNewEventsFile from "./pages/AddNewEventsFile";
import EventList from "./pages/MasterEvents/EventList";
import Documents from "./pages/Documents";
import AllSecretaries from "./pages/AllSecretaries";
import AddNewSecretary from "./pages/AddNewSecretary";
import DocTypeList from "./pages/DocTypeList";
import MarkedCatalog from "./pages/MarkedCatalog";
import AdminSettings from "./pages/AdminSettings";
import ViewDocuments from "./pages/ViewDocuments";
import LocalStorageManager from './components/LocalStorageManager';
import { UserLogs } from "./pages/logs/UserLogs";
import { FileUploadLogs } from "./pages/logs/FileUploadLogs";

function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <LocalStorageManager>
        <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/set-password" element={<SetNewPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="/" element={<ProtectedLayout />}>
              <Route path="event-list" element={<EventList />} />
              <Route path="event-documents" element={<Documents />} />
              <Route path="add-new-file" element={<AddNewEventsFile />} />
              <Route path="my-account" element={<MyAccount />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="documents" element={<ViewDocuments />} />

              <Route path="users" element={<AllSecretaries />} />
              <Route path="add-new-secretary" element={<AddNewSecretary />} />
              <Route path="document-types" element={<DocTypeList />} />
              <Route path="marked-catalog" element={<MarkedCatalog />} />
              <Route path="settings" element={<AdminSettings />} />

              <Route path="user-logs" element={<UserLogs />} />
              <Route path="file-upload-logs" element={<FileUploadLogs />} />
            </Route>
        </Routes>
      </LocalStorageManager>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
