//url end points

export const LOGIN = "/api/v1/user/login";
export const LOGOUT = "/api/v1/user/logout";
export const FORGOT_PASSWORD = "/api/v1/user/forgot-password";
export const RESET_PASSWORD = "/api/v1/user/create-password";
export const CHANGE_PASSWORD = "/api/v1/user/change-password";
export const RESET_USER_PASSWORD = "/api/v1/user/update-secretary";

export const GET_EVENTS="/api/v1/event";
export const REFRESH_EVENTS="/api/v1/event/refresh_events";
export const UPDATE_EVENT_STATUS="/api/v1/event/event_status";
export const SET_SIGNED_OUT="/api/v1/event/signed_out";
export const GET_SHOWS="/api/v1/show";

export const ADD_EVENT = "/api/v1/document";
export const GET_SEC = "/api/v1/user";
export const ACTIVATE_ACCOUNT="/api/v1/admin/create-user";

export const DOC_TYPES = "/api/v1/documentTypes";
export const DOC_DELETE = "/api/v1/document";
export const GET_DOCS = "/api/v1/document";

export const GET_SETTINGS="/api/v1/adminSettings";
export const SHOW_NOTES="/api/v1/showNotes";
export const ADMIN_DOCUMENTS="/api/v1/adminDocuments";
export const GET_ADMIN_DOCUMENTS="/api/v1/adminDocuments/all";

export const GET_USER_LOGS="/api/v1/logs/user";
export const GET_FILE_UPLOAD_LOGS="/api/v1/logs/event";
export const UPDATE_FILE_UPLOAD_LOGS="/api/v1/logs/event/update";