import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Row,
  Button,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import "../styles.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useCallback } from "react";

import { useLocation } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {} from "../store/documents/actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { delDoc, getDocs } from "../store/documents/actions";
import { useAuth } from "../hooks/useAuth";

function Documents() {
  const navigate = useNavigate();

  const location = useLocation();
  const [delModal, setDelModal] = useState(false);
  const [del_id, setDelId] = useState();
  const { loadingDelDoc, docList, loadingGetDocs, delDocResponse } =
    useSelector((state) => state.DocReducer);
  let dispatch = useDispatch();
  const { user, logout } = useAuth();
  const [newDocList, setNewDocList] = useState();
  // console.log("documents", location.state.master_event);
  // columns for the list of event documents
  const columns = useMemo(
    () => [
      {
        name: "Event Name",
        selector: (row) =>
          row.showName ? row.showName : location.state.event.show_name,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Event Number",
        selector: (row) =>
          location.state.event.ckc_number
            ? location.state.event.ckc_number
            : location.state.event.canuck_event_id,
        sortable: true,
        wrap: true,
        grow: 1,
      },
      {
        name: "Document Type",
        selector: (row) => row.type,
        sortable: true,
        wrap: true,
        grow: 1,
      },

      {
        name: "File Name",
        selector: (row) => row.data_file.originalname,
        sortable: true,
        wrap: true,
        grow: 3,
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="View"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                href={
                  process.env.REACT_APP_APP_URL +
                  "api/v1/document?document_id=" +
                  row._id
                }
              >
                <VisibilityIcon />
              </Button>
              <Button
                title="Delete"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                onClick={() => deleteToggle(row._id)}
              >
                <DeleteForeverIcon />
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );
  // columns for the list of event documents

  const columns2 = useMemo(
    () => [
      {
        name: "Event Name",
        selector: (row) =>
          row.showName ? row.showName : location.state.event.show_name,
        sortable: true,
        wrap: true,
        grow: 2,
      },
      {
        name: "Document Type",
        selector: (row) => row.type,
        sortable: true,
        wrap: true,
        grow: 1,
      },

      {
        name: "File Name",
        selector: (row) => row.data_file.originalname,
        sortable: true,
        wrap: true,
        grow: 3,
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 1,
        cell: (row) => (
          <Row>
            <Col>
              <Button
                title="View"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                href={
                  process.env.REACT_APP_APP_URL +
                  "api/v1/document?document_id=" +
                  row._id
                }
              >
                <VisibilityIcon />
              </Button>
              <Button
                title="Delete"
                target="_blank"
                size="sm"
                outline
                className="manage-event"
                onClick={() => deleteToggle(row._id)}
              >
                <DeleteForeverIcon />
              </Button>
            </Col>
          </Row>
        ),
      },
    ],
    []
  );
  const deleteToggle = (del_id) => {
    setDelModal(!delModal);
    setDelId(del_id);
  };
  useEffect(() => {
    if (!loadingDelDoc) {
      setDelModal(false);
      dispatch(getDocs(location.state.event.event_id, user.token));
    }
  }, [loadingDelDoc]);

  useEffect(() => {
    if (location.state.event.show_id) {
      setNewDocList(
        docList.filter((obj) => {
          return obj.show_id === location.state.event.show_id;
        })
      );
    } else {
      setNewDocList(
        docList.filter((obj) => {
          return obj.show_id === "";
        })
      );
    }
  }, [docList, location.state.event]);

  // dispatch delete any document of event
  const deleteDoc = (id) => {
    const delData = {
      document_id: id,
    };
    console.log(delData);
    dispatch(delDoc(delData, user.token));
  };

  const moveBack = () => {
    navigate("/event-list", {
      state: { master_event: location.state.master_event },
    });
  };
  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">
        {"Documents (" + location.state.master_event_name + ")"}
        <nbsp />
      </p>

      <ToastContainer />

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <Button 
            style={{ float: "right", marginRight: "10px", fontSize: "16px" }}
            onClick={() => moveBack()}>Back</Button>{' '}
            {location.state.event.show_name}
          </CardHeader>

          <CardBody className="event-card-body">
            <DataTable
              columns={location.state.event.show_id ? columns : columns2}
              data={newDocList}
              theme=""
              pagination
              progressPending={loadingGetDocs}
              paginationRowsPerPageOptions={[50, 100]}
              paginationPerPage={50}
              highlightOnHover
              fixedHeader
            />
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={delModal} toggle={deleteToggle}>
        <ModalHeader toggle={deleteToggle}>Delete Document</ModalHeader>
        <ModalBody>Are you sure you want to Delete this document?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteDoc(del_id)}>
            {loadingDelDoc ? "Deleting..." : "Yes, Delete"}
          </Button>{" "}
          <Button color="secondary" onClick={deleteToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Documents;
