export const GET_USER_LOGS = "GET_USER_LOGS";
export const GET_USER_LOGS_SUCCESS = "GET_USER_LOGS_SUCCESS";
export const GET_USER_LOGS_FAIL = "GET_USER_LOGS_FAIL";

export const GET_File_UPLOAD_LOGS = "GET_File_UPLOAD_LOGS";
export const GET_File_UPLOAD_LOGS_SUCCESS = "GET_File_UPLOAD_LOGS_SUCCESS";
export const GET_File_UPLOAD_LOGS_FAIL = "GET_File_UPLOAD_LOGS_FAIL";

export const UPDATE_FILE_UPLOAD_LOGS = "UPDATE_FILE_UPLOAD_LOGS";
export const UPDATE_File_UPLOAD_LOGS_SUCCESS = "UPDATE_File_UPLOAD_LOGS_SUCCESS";
export const UPDATE_File_UPLOAD_LOGS_FAIL = "UPDATE_File_UPLOAD_LOGS_FAIL";