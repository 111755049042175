export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";


export const ADD_NOTE="ADD_NOTE";
export const ADD_NOTE_SUCCESS="ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAILURE="ADD_NOTE_FAILURE";

export const DEL_NOTE="DEL_NOTE";
export const DEL_NOTE_SUCCESS="DEL_NOTE_SUCCESS";
export const DEL_NOTE_FAILURE="DEL_NOTE_FAILURE";



export const EDIT_NOTE="EDIT_NOTE";
export const EDIT_NOTE_SUCCESS="EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE="EDIT_NOTE_FAILURE";

