import {
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAIL,
  ADD_NOTE,
  ADD_NOTE_FAILURE,
  ADD_NOTE_SUCCESS,
  EDIT_NOTE,
  EDIT_NOTE_SUCCESS,
  EDIT_NOTE_FAILURE,
  DEL_NOTE,
  DEL_NOTE_FAILURE,
  DEL_NOTE_SUCCESS
} from "./actionTypes";

export const addNote= (data,navigate,token) => {
  return {
    type: ADD_NOTE,
    token:token,
    payload:data,
    navigate:navigate
  };
};

export const addNoteSuccess = (response) => {
  return {
    type: ADD_NOTE_SUCCESS,
    payload: response,
  };
};

export const addNoteFail = (error) => {
  return {
    type:ADD_NOTE_FAILURE,
    payload: error,
  };
};


export const getNotes= (eventId,token) => {
  return {
    type: GET_NOTES,
    token:token,
    eventId:eventId
  };
};

export const getNotesSuccess = (noteList) => {
  return {
    type: GET_NOTES_SUCCESS,
    payload: noteList,
  };
};

export const getNotesFail = (error) => {
  return {
    type:GET_NOTES_FAIL,
    payload: error,
  };
};




export const delNote = (data,navigate,token) => {
  return {
    type: DEL_NOTE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const delNoteSuccess = (delNoteResponse) => {
  return {
    type: DEL_NOTE_SUCCESS,
    payload:delNoteResponse,
  };
};

export const delNoteFailure = (error) => {
  return {
    type: DEL_NOTE_FAILURE,
    payload:error,
  };
};





export const editNote = (data,token,navigate) => {
  return {
    type: EDIT_NOTE,
    payload: data,
    navigate:navigate,
    token:token
  };
};

export const editNoteSuccess = (editNoteResponse) => {
  return {
    type: EDIT_NOTE_SUCCESS,
    payload:editNoteResponse,
  };
};

export const editNoteFailure = (error) => {
  return {
    type: EDIT_NOTE_FAILURE,
    payload:error,
  };
};

