
export const LOGIN="LOGIN";
export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_FAILURE="LOGIN_FAILURE";


export const FORGOT_PASSWORD="FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE="FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD="RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE="RESET_PASSWORD_FAILURE";

export const CHANGE_PASSWORD="CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS="CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE="CHANGE_PASSWORD_FAILURE";

