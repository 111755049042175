import { takeLatest, put, call } from "redux-saga/effects";

import { DEL_DOC,GET_DOCS} from "./actionTypes";

import {
  delDocSuccess,
  delDocFailure,
  getDocsFail,
  getDocsSuccess
  
} from "./actions";

import {delDoc,getDocs} from "../../helpers/backend_helper";
import 'react-toastify/dist/ReactToastify.css';

function* onGetDocs({payload:eventId,token}) {

  
  try {
    const response = yield call(getDocs,eventId,token);
    // console.log(response);
  
    yield put(getDocsSuccess(response.data));
  } catch (error) {
    yield put(getDocsFail(error.response.data));
  }
}
function* onDelDoc({ payload: data,token }) {

  
  try {
    const response = yield call(delDoc,data,token);
    // console.log(response);
    yield put(delDocSuccess(response.data));
  } catch (error) {
    yield put(delDocFailure(error.response));
  }
}




function* DocSaga() {

  yield takeLatest(DEL_DOC, onDelDoc);
  yield takeLatest(GET_DOCS, onGetDocs);


}

export default DocSaga;
