import { takeLatest, put, call } from "redux-saga/effects";

import { GET_DOC_TYPES,DEL_DOC_TYPE,EDIT_DOC_TYPE,ADD_DOC_TYPE} from "./actionTypes";

import {
  getDocTypesSuccess,
  getDocTypesFail,
  delDocTypeFailure,
  delDocTypeSuccess,
  editDocTypeFailure,
  editDocTypeSuccess,
  addDocTypeFail,
  addDocTypeSuccess
  
} from "./actions";

import { getDocTypes,delDocType,editDocType,addDocType} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function* onGetDocTypes({token}) {

  
  try {
    const response = yield call(getDocTypes,token);
    console.log(response);
  
    yield put(getDocTypesSuccess(response.data));
  } catch (error) {
    yield put(getDocTypesFail(error.response.data));
  }
}

function* onDelDocType({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(delDocType,data,token);
    console.log(response);
    toast(response.message);
    if(response.statusCode===200){
      navigate(0);

    }
    yield put(delDocTypeSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(delDocTypeFailure(error.response));
  }
}




function* onEditDocType({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(editDocType,data,token);
   // console.log(response);
    toast(response.message);
    if(response.statusCode===200){
      navigate(0);

    }
    yield put(editDocTypeSuccess(response.data));
  } catch (error) {
    toast(error.response.data.message);
    yield put(editDocTypeFailure(error.response));
  }
}



function* onAddDocType({ payload: data,navigate,token }) {

  
  try {
    const response = yield call(addDocType,data,token);
   // console.log(response);
    toast(response.message);
    if(response.statusCode===200){
      navigate(0);

    }
    yield put(addDocTypeSuccess(response.data));
  } catch (error) {
    toast(error.response.data.data);
    yield put(addDocTypeFail(error.response));
  }
}


function* DocTypesSaga() {

  yield takeLatest(GET_DOC_TYPES, onGetDocTypes);
  yield takeLatest(DEL_DOC_TYPE, onDelDocType);
  yield takeLatest(EDIT_DOC_TYPE, onEditDocType);
  yield takeLatest(ADD_DOC_TYPE, onAddDocType);

}

export default DocTypesSaga;
