import {
  DEL_DOC,
  DEL_DOC_FAILURE,
  DEL_DOC_SUCCESS,
  GET_DOCS,
  GET_DOCS_SUCCESS,
  GET_DOCS_FAIL
} from "./actionTypes";

const initialState = {
  loading: false,
  error: {
    message: "",
    code:"",
  },
  delDocResponse: {},
  loadingDelDoc: false,
loadingGetDocs:false,
docList:[],
 

};

const DocReducer = (state = initialState, action) => {


  switch (action.type) {
    case GET_DOCS:
      state = { ...state, loadingGetDocs: true };
      break;
    case GET_DOCS_SUCCESS:
      state = { ...state, docList: action.payload.documents, loadingGetDocs: false };
      break;
    case GET_DOCS_FAIL:

      state = {
        ...state,
        error: {
          message: action.payload.message
                },
        loadingGetDocs: false,
      };
      break;
    case DEL_DOC:
      state = { ...state, loadingDelDoc: true };
      break;
    case DEL_DOC_SUCCESS:
      state = { ...state, delDocResponse: action.payload, loadingDelDoc: false };

      break;
    case DEL_DOC_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelDoc: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DocReducer;
