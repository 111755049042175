import {  CardHeader } from "reactstrap";

import React, { useState, useMemo } from "react";
import {
  Card,
  CardBody,
} from "reactstrap";
import { useLocation } from "react-router-dom";

function ViewDocuments() {
  let locations = useLocation();
  console.log(locations.state.docItem);
  let item = locations.state.docItem;

  return (
    <div style={{ backgroundColor: "#F1F2F4" }}>
      <p className="page-heading">Admin Documents</p>

      <div className="page-body">
        <Card className="my-2" style={{ padding: 0 }}>
          <CardHeader>
            <b>{item.docName}</b>
          </CardHeader>

          <CardBody>
            <iframe
              width="100%"
              height="600px"
              title="Document Video"
              src={
                item.docType === "file"
                  ? process.env.REACT_APP_APP_URL +
                    "api/v1/adminDocuments?document_id=" +
                    item._id
                  : item.link
              }
            ></iframe>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ViewDocuments;
