import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_EVENTS,
  REFRESH_EVENTS,
  ADD_EVENT,
  ADD_SHOW_DOCUMENT,
  GET_EVENT_LIST, GET_SHOWS_LIST, UPDATE_EVENT_STATUS, SET_SIGNED_OUT
} from "./actionTypes";

import {

  addEventSuccess,
  addEventFailure,
  getEventListFail,
  getEventListSuccess,
  getShowsListSuccess,
  getShowsListFail,
  refreshEventsSuccess,
  refreshEventsFail,
  updateEventStatusFailure,
  updateEventStatusSuccess,
  addShowDocumentFailure,
  addShowDocumentSuccess,
  setSignedOutFailure,
  setSignedOutSuccess
  
} from "./actions";

import {
  addEvent,updateFileUploadLogs,
  getEventList, getShowsList, refreshEvents, updateEventStatus, setSignedOut
} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function* onAddShowDoc({ payload: data, token, navigate }) {

  let originalFileName = '';
  let fileExtension = '';

  // Extract file details from FormData
  data.forEach((value, key) => {
    if (value instanceof File) {
      originalFileName = value.name;
      fileExtension = value.name.split('.').pop();
    }
  });

  // Prepare JSON payload with extracted file details
  const formDataObj = {
    type: data.get("type"),
    doc_type_id: data.get("doc_type_id"),
    showName: data.get("showName"),
    show_id: data.get("show_id"),
    show_status: data.get("show_status"),
    event_id: data.get("event_id"),
    event_status: data.get("event_status"),
    user_id: data.get("user_id"),
    originalFileName, // Add extracted file details directly
    fileExtension,    // Add extracted file details directly
  };

  // console.log("FormData contents as JSON: ", formDataObj);


  try {
    const response = yield call(addEvent, data, token);
    // console.log("onAddShowDoc >> ",response);
    const filelogsId = response?.fileslogId;
    if (filelogsId) {
      // console.log("filelogsId >> 11 >> ", filelogsId);
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    toast(response.message);
    yield put(addShowDocumentSuccess(response.data));
  } catch (error) {
    //  toast(error.response.data.message);
    const filelogsId = error?.response?.data?.fileslogId;
    if (filelogsId) {
      // console.log("filelogsId >> 22 >> ", filelogsId);
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    yield put(addShowDocumentFailure(error.response));
  }
}

function* onAddEvent({ payload: data, token, navigate }) {
  console.log("onAddEvent >> data >> ", data);
  // for (const [key, value] of data.entries()) {
  //   console.log(`data >> onAddEvent >> data >> ${key}: ${value}`);
  // }
  let originalFileName = '';
  let fileExtension = '';

  // Extract file details from FormData
  data.forEach((value, key) => {
    if (value instanceof File) {
      originalFileName = value.name;
      fileExtension = value.name.split('.').pop();
    }
  });

  // Prepare JSON payload with extracted file details
  const formDataObj = {
    type: data.get("type"),
    doc_type_id: data.get("doc_type_id"),
    showName: data.get("showName"),
    show_id: data.get("show_id"),
    show_status: data.get("show_status"),
    event_id: data.get("event_id"),
    event_status: data.get("event_status"),
    user_id: data.get("user_id"),
    originalFileName, // Add extracted file details directly
    fileExtension,    // Add extracted file details directly
  };

  // console.log("FormData contents as JSON: ", formDataObj);

  try {
    const response = yield call(addEvent, data, token);
    console.log("response >> ", response);
    const filelogsId = response?.fileslogId;
    if (filelogsId) {
      console.log("filelogsId >> 11 >> ", filelogsId);
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    toast(response.message);
    // if(response.statusCode===200){
    //   navigate(0);

    // }
    yield put(addEventSuccess(response.data));
  } catch (error) {
    //  toast(error.response.data.message);
    console.log("error >> ", error);
    const filelogsId = error?.response?.data?.fileslogId;
    if (filelogsId) {
      console.log("filelogsId >> 22 >> ", filelogsId);
      formDataObj.filelogsId = filelogsId;
      yield call(updateFileUploadLogs, formDataObj, token);
    }
    yield put(addEventFailure(error.response));
  }
}

function* onGetEventList({ payload: eventFileId, token }) {


  try {
    const response = yield call(getEventList, eventFileId, token);
    console.log("Event List", response);

    yield put(getEventListSuccess(response.data));
  } catch (error) {
    yield put(getEventListFail(error.response));
  }
}


function* onRefreshEvents({ token }) {


  try {
    const response = yield call(refreshEvents, token);
    console.log("Refresh Events", response);

    yield put(refreshEventsSuccess(response.data));
  } catch (error) {
    yield put(refreshEventsFail(error.response));
  }
}
function* onGetShowList({ payload: eventId, token }) {


  try {
    const response = yield call(getShowsList, eventId, token);
    console.log("Shows List", response);

    yield put(getShowsListSuccess(response.data));
  } catch (error) {
    yield put(getShowsListFail(error.response));
  }
}



function* onUpdateEventStatus({ payload: data, navigate, token }) {


  try {
    const response = yield call(updateEventStatus, data, token);
    // console.log(response);
    if (response.statusCode === 400)
      toast(response.data);
    yield put(updateEventStatusSuccess(response.data));
  } catch (error) {
    console.log(error);
    toast(error.response.data.message);
    yield put(updateEventStatusFailure(error.response));
  }
}

function* onSetSignedOut({ payload: data, token }) {


  try {
    const response = yield call(setSignedOut, data, token);
    // console.log(response);
    if (response.statusCode === 400)
      toast(response.data);
    yield put(setSignedOutSuccess(response.data));
  } catch (error) {
    console.log(error);
    toast(error.response.data.message);
    yield put(setSignedOutFailure(error.response));
  }
}
function* EventsSaga() {

  yield takeLatest(ADD_EVENT, onAddEvent);
  yield takeLatest(ADD_SHOW_DOCUMENT, onAddShowDoc);
  yield takeLatest(GET_EVENT_LIST, onGetEventList);
  yield takeLatest(GET_SHOWS_LIST, onGetShowList);
  yield takeLatest(REFRESH_EVENTS, onRefreshEvents);
  yield takeLatest(UPDATE_EVENT_STATUS, onUpdateEventStatus);
  yield takeLatest(SET_SIGNED_OUT, onSetSignedOut);

}

export default EventsSaga;
