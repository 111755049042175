import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { postLogin } from "../helpers/api_helper";
import { LOGOUT } from "../helpers/url_helper";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);
    navigate("/event-list", { replace: true });
  };

  const logout = async () => {
    try {
      await postLogin(LOGOUT, { email: user.email });
      setUser(null);
      navigate("/", { replace: true });
    } catch (error) {
      // console.error("Logout failed", error);
      setUser(null);
      navigate("/", { replace: true });
    }
  };


  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
