import axios from "axios";

//apply base url for axios
const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// get request with token
export async function get(url,token, config) {
  
  return await axiosApi
    .get(url, {
      headers:{'Authorization':"Bearer "+token},
      ...config
    })
    .then((response) => response.data);
}
//post request for login without token
export async function postLogin(url, data,config) {
  return await axiosApi
    .post(url,data, {
      ...config,
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => response);

}

// post with token
export async function post(url, data,token,config) {
  return await axiosApi
    .post(url,data, {
      ...config,
      headers: { 'Authorization': "Bearer "+token,'Content-Type': 'application/json' }
    })
    .then((response) => response.data);

}
//post multipart/form-data
export async function postEvent(url, data,token,config) {
  return await axiosApi
    .post(url,data, {
      ...config,
      headers: {  'Authorization': "Bearer "+token,'content-type': 'multipart/form-data','Access-Control-Allow-Origin': '*' }
    })
    .then((response) => response.data);
}

// put with token
export async function put(url, data,config) {
  return await axiosApi
    .put(url,data, {
      ...config,
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => response.data);
}

// put without token
export async function putWithoutToken(url, data,config) {
  return await axiosApi
    .put(url,data, {
      ...config,
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => response.data);
}


// delete with token
export async function delItem(url, data,token,config) {
  return await axiosApi
    .delete(url, {
      ...config,
      headers: { 'Authorization': "Bearer "+token,'Content-Type': 'application/json' },
      data
    })
    .then((response) => response.data);
}