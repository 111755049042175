import { all, fork } from "redux-saga/effects";

import AuthSaga from "./auth/saga";
import EventsSaga from "./events/saga";
import SecSaga from "./secretaries/saga";
import DocTypesSaga from "./documentTypes/saga";
import DocSaga from "./documents/saga";
import SettingsSaga from "./adminSettings/saga";
import NotesSaga from "./showNotes/saga";
import LogsSaga from "./logs/saga";

export default function* rootSaga() {
  yield all([fork(AuthSaga)]);
  yield all([fork(EventsSaga)]);
  yield all([fork(SecSaga)]);
  yield all([fork(DocTypesSaga)]);
  yield all([fork(DocSaga)]);
  yield all([fork(SettingsSaga)]);

  yield all([fork(NotesSaga)]);
  yield all([fork(LogsSaga)]);


}
